import "../App.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Image, Badge } from "react-bootstrap";
import { Link, useHistory, NavLink, useLocation } from "react-router-dom";
import LogoutModal from "./LogoutModal";
import { useSelector } from "react-redux";

//icons

import Menu from "../convertedIcons/icons/Menu";
import Activity from "../convertedIcons/icons/Activity";
import FingerPrint from "../convertedIcons/icons/FingerPrint";
import BookMark from "../convertedIcons/icons/BookMark";
import Users from "../convertedIcons/icons/Users";
import Notes from "../convertedIcons/icons/Notes";
import MyLaserApi from "../api/MyLaserApi";
import moment from "moment";

const fullWidth = window.innerWidth;

const Container = styled.div`
    background-color: #fff;
    padding-top: 10px;
    min-width: 200px;
    max-width: 200px;
    height: 100vh;
    padding-bottom: 200px;
    margin-bottom: 200px;
`;

const SubContainer = styled.div`
    background-color: #fff;
    position: fixed;
    height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    min-width: 200px;
    max-width: 200px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 100vh;
    margin-bottom: 100vh;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const ButtonsDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    height: 100%;
    width: 100%;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    background-color: transparent;
    border: transparent;
    margin-top: 10px;
`;

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #4f5557;
    font-size: ${fullWidth < 1375 ? "11px" : "14px"};
    &.selected {
        font-family: CenturyGothicBold;
        color: #102935;
    }
    &.black {
        color: #102935;
    }
    &.small {
        font-size: 11px;
        align-self: flex-start;
    }
    &.selected-option {
        font-family: CenturyGothicBold;
        color: #f0b594;
    }
`;

const UnminimizeButton = styled.button`
    position: absolute;
    bottom: 20px;
    left: 20px;
    border: 1px solid black;
    color: white;
    background: black;
    z-index: 999999;
`;

const MinimizeButton = styled.button`
    position: fixed;
    bottom: 20px;
    left: 20px;
    border: 1px solid black;
    color: white;
    background: black;
    z-index: 999999999999;
`;

const menus = {
    patient: [
        {
            name: "Info patient",
            path: ({ client, user }) => `/patients/${client.id}/informations`,
            selectedRegex: /\/informations/,
            icon: Menu,
            show: () => true,
        },
        {
            name: "Devis",
            path: ({ client, user }) => `/patients/${client.id}/quotations`,
            selectedRegex: /\/quotations/,
            icon: BookMark,
            show: () => true,
            subs: [
                {
                    name: "Laser",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/quotations`,
                    selectedRegex: /\/quotations$/,
                    show: () => true,
                },
                {
                    name: "Soins Visage et Corps",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/quotations/soins`,
                    selectedRegex: /\/quotations\/soins/,
                    show: () => true,
                },
                {
                    name: "Médecine Esthétique",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/quotations/mes`,
                    selectedRegex: /\/quotations\/mes/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }

                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
            ],
        },
        {
            name: "Info traitement",
            path: ({ client, user }) =>
                `/patients/${client.id}/treatments/laser`,
            selectedRegex: /\/treatments/,
            icon: Activity,
            show: ({ client, user }) => {
                if (
                    client &&
                    client.birthdate &&
                    moment().diff(client.birthdate, "years", false) < 18 &&
                    !client.has_parental_authorization
                ) {
                    return false;
                }
                return true;
            },
            subs: [
                {
                    name: "Epilation laser",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/laser`,
                    selectedRegex: /\/(laser)$/,
                    show: () => true,
                    bold: true,
                },

                {
                    name: "Soins visage",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/mesotherapy`,
                    selectedRegex:
                        /\/(mesotherapy|hydrafacial|prx|laser-frax|carbon-peel)$/,
                    show: () => true,
                    bold: true,
                },

                {
                    name: "=== Mesotherapie",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/mesotherapy`,
                    selectedRegex: /\/(mesotherapy)$/,
                    show: ({ client, user }) => {
                        return true;
                    },
                },
                {
                    name: "=== Hydrafacial",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/hydrafacial`,
                    selectedRegex: /\/(hydrafacial)$/,
                    show: ({ client, user }) => {
                        if (window.country === "ma") {
                            return false;
                        }

                        return true;
                    },
                },
                {
                    name: "=== Hollywood Peel",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/carbon-peel`,
                    selectedRegex: /\/(carbon-peel)$/,
                    show: ({ client, user }) => true,
                },
                {
                    name: "=== Prx",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/prx`,
                    selectedRegex: /\/(prx)$/,
                    show: ({ client, user }) => {
                        if (window.country === "ma") {
                            return false;
                        }

                        return true;
                    },
                },
                {
                    name: "=== Laser Frax Réjuvénation",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/laser-frax`,
                    selectedRegex: /\/(laser-frax)$/,
                    show: ({ client, user }) => {
                        if (window.country === "ma") {
                            return false;
                        }

                        return true;
                    },
                },
                {
                    name: "=== Laser Frax (M.E)",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/laser-frax-mes`,
                    selectedRegex: /\/(laser-frax-mes)$/,
                    show: ({ client, user }) => true,
                },
                {
                    name: "=== Blemiderm",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/blemiderm`,
                    selectedRegex: /\/(blemiderm)$/,
                    show: ({ client, user }) => {
                        if (window.country === "ma") {
                            return false;
                        }

                        return true;
                    },
                },

                {
                    name: "=== Peri-Oculaire + Mésothérapie",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/peri-oculaire-meso`,
                    selectedRegex: /\/(peri-oculaire-meso)$/,
                    show: ({ client, user }) => {
                        if (window.country === "ma") {
                            return false;
                        }

                        return true;
                    },
                },

                {
                    name: "Soins corps & Cheveux",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/prp`,
                    selectedRegex: /\/(prp|hair-bleaching|cryolipolise)$/,
                    show: () => true,
                    bold: true,
                },
                {
                    name: "=== PRP",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/prp`,
                    selectedRegex: /\/(prp)$/,
                    show: ({ client, user }) => true,
                },
                {
                    name: "=== Hair Bleaching",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/hair-bleaching`,
                    selectedRegex: /\/(hair-bleaching)$/,
                    show: ({ client, user }) => true,
                },
                {
                    name: "=== Cryolipolise",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/cryolipolise`,
                    selectedRegex: /\/(cryolipolise)$/,
                    show: ({ client, user }) => true,
                },
                {
                    name: "=== Peeling Intime",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/peeling-intimate`,
                    selectedRegex: /\/(peeling-intimate)$/,
                    show: ({ client, user }) => true,
                },
                {
                    name: "=== Détatouage",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/detatouage`,
                    selectedRegex: /\/(detatouage)$/,
                    show: ({ client, user }) => true,
                },

                {
                    name: "Médecine esthétique",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/ah`,
                    selectedRegex: /\/(ah|botox|peeling)$/,
                    bold: true,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }

                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
                {
                    name: "=== Injection AH",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/ah`,
                    selectedRegex: /\/(ah)$/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },

                {
                    name: "=== Peeling TCA",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/peeling`,
                    selectedRegex: /\/(peeling)$/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
                {
                    name: "=== Varicosité / Couperose ",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/varicosite-couperose`,
                    selectedRegex: /\/(varicosite-couperose)$/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
                {
                    name: "=== Laser Frax ",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/laser-frax-mes`,
                    selectedRegex: /\/(laser-frax-mes)$/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
                {
                    name: "=== Acné Active ",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/acne-active`,
                    selectedRegex: /\/(acne-active)$/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
                {
                    name: "=== Tâches Brunes/Mél./Len.",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/taches-brune`,
                    selectedRegex: /\/(taches-brune)$/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
                {
                    name: "=== Détatouage",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/detatouage`,
                    selectedRegex: /\/(detatouage)$/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
                {
                    name: "=== Endolift",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/endolift`,
                    selectedRegex: /\/(endolift)$/,
                    show: ({ client, user }) => {
                        if (window.country === "ma") {
                            return false;
                        }

                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },

                {
                    name: "=== Dermamelan",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/dermamelan`,
                    selectedRegex: /\/(dermamelan)$/,
                    show: ({ client, user }) => {
                        if (window.country === "ma") {
                            return false;
                        }

                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },

                {
                    name: window.country === "ma" ? "=== " : "=== Botox",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/botox`,
                    selectedRegex: /\/(botox)$/,
                    show: ({ client, user }) => {
                        if (
                            client &&
                            client.birthdate &&
                            moment().diff(client.birthdate, "years", false) < 18
                        ) {
                            return false;
                        }
                        return (
                            user.role == 6 ||
                            user.role == 5 ||
                            user.role == 8 ||
                            user.role == 1 ||
                            user.role === 7
                        );
                    },
                },
                // {
                //     name: "Autres traitements",
                //     path: ({client, user}) => `/patients/${client.id}/treatments/cryolipolyse`,
                //     selectedRegex: /\/(cryolipolyse|hair|peeling-intimate)$/,
                //     show: () => true
                // },
                {
                    name: "Traitements non catégorisés",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/unknow`,
                    selectedRegex: /\/(unknow)$/,
                    show: () => true,
                    bold: true,
                },
                {
                    name: ({ client }) => (
                        <>
                            Notes particulières{" "}
                            {client.special_note != "0" && (
                                <span style={{ color: "red" }}>
                                    ({client.special_note})
                                </span>
                            )}
                        </>
                    ),
                    path: ({ client, user }) =>
                        `/patients/${client.id}/treatments/laser/notes`,
                    selectedRegex: /\/laser\/notes/,
                    show: () => true,
                    bold: true,
                },
                {
                    name: "Consentements traitements",
                    path: ({ client, user }) =>
                        `/patients/${client.id}/consents`,
                    selectedRegex: /\/consents$/,
                    show: () => true,
                    bold: true,
                },
                // {
                //     name: "Achat de produits",
                //     path: ({ client, user }) =>
                //         `/patients/${client.id}/products`,
                //     selectedRegex: /\/products$/,
                //     show: () => true,
                //     bold: true,
                // },
            ],
        },
        {
            name: "Info paiement",
            path: ({ client }) => `/patients/${client.id}/payments`,
            selectedRegex: /\/payments/,
            icon: FingerPrint,
            show: () => true,
        },
    ],
};

function SideBar() {
    const [disconnectModalVisible, showDisconnectModal] = useState(false);

    let history = useHistory();
    let location = useLocation();
    let [type, setType] = useState("hide");
    let [minimize, setMinimize] = useState(false);

    const client = useSelector((state) => state.client) || {};
    const agencies = useSelector((state) => state.agencies) || [];
    const user = useSelector((state) => state.user) || {};

    const [mnp, setMnp] = useState([]);

    let loadType = () => {
        let pathname = location.pathname;

        if (["/home", "/patients", "/notenprotocoles"].includes(pathname)) {
            setType("normal");
        } else if (["/account", "/activity"].includes(pathname)) {
            setType("employee-account");
        } else if (pathname.startsWith("/patients/")) {
            setType("patient");
        } else {
            setType("normal");
        }

        if (
            pathname.includes("add") ||
            pathname.includes("update") ||
            pathname.includes("login") ||
            pathname.includes("/consent/") ||
            pathname.includes("/consents/")
        ) {
            setType("hide");
        }
    };

    useEffect(() => {
        MyLaserApi.getAgencies();
    }, []);

    useEffect(() => {
        loadType();

        MyLaserApi.call({
            method: "GET",
            url: `/users/me/sessions/notes`,
        }).then((res) => {
            if (res.status === 200) {
                setMnp(res.data);
            }
        });
    }, [location, user, agencies]);

    if (type === "hide") {
        return <></>;
    }

    if (minimize) {
        return (
            <UnminimizeButton onClick={() => setMinimize(false)}>
                Ouvrir le menu
            </UnminimizeButton>
        );
    }

    return (
        <Container>
            {type === "patient" && (
                <MinimizeButton onClick={() => setMinimize(true)}>
                    Fermer le menu
                </MinimizeButton>
            )}
            <SubContainer>
                <span style={{ width: "100%", textDecorationLine: "none" }}>
                    {type === "employee-dashboard" ||
                    type === "admin" ||
                    type == "normal" ? (
                        <Image
                            src={"/logo-bleu.svg"}
                            style={{ paddingLeft: 5 }}
                        />
                    ) : (
                        <Row
                            onClick={() => {
                                if (user.role === 1) {
                                    history.push("/admin");
                                } else {
                                    history.push("/home");
                                }
                            }}
                        >
                            <Image src={"/back.svg"} />
                            <Text className="black">
                                Retour au menu principal
                            </Text>
                        </Row>
                    )}
                </span>

                {(() => {
                    if (type === "normal") {
                        if (user.role === 1) {
                            return (
                                <ButtonsDiv>
                                    <NavLink
                                        to={`/admin`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <Menu
                                                selected={/\/admin$/.test(
                                                    location.pathname
                                                )}
                                            />
                                            <Text
                                                className={
                                                    /\/admin$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Praticiens
                                            </Text>
                                        </Row>
                                    </NavLink>

                                    <NavLink
                                        to={`/admin/sessions`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <BookMark
                                                selected={/\/sessions$/.test(
                                                    location.pathname
                                                )}
                                            />
                                            <Text
                                                className={
                                                    /\/sessions$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Prestations
                                            </Text>
                                        </Row>
                                    </NavLink>

                                    <Link
                                        to={`/admin/patients`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <Users
                                                selected={/\/patients$/.test(
                                                    location.pathname
                                                )}
                                            />

                                            <Text
                                                className={
                                                    /\/patients/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Patients
                                            </Text>
                                        </Row>
                                    </Link>

                                    <Link
                                        to={`/admin/agencies`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <Activity
                                                selected={/\/agencies$/.test(
                                                    location.pathname
                                                )}
                                            />

                                            <Text
                                                className={
                                                    /\/agencies$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Agences
                                            </Text>
                                        </Row>
                                    </Link>

                                    <Col>
                                        {agencies &&
                                            agencies.map((agency) => {
                                                return (
                                                    <Link
                                                        to={`/admin/agencies/${agency.id}`}
                                                        style={{
                                                            width: "100%",
                                                            textDecorationLine:
                                                                "none",
                                                        }}
                                                    >
                                                        <Text
                                                            className={`small ${
                                                                /\/admin\/agencies/.test(
                                                                    location.pathname
                                                                ) &&
                                                                location.pathname.includes(
                                                                    agency.id
                                                                )
                                                                    ? "selected-option"
                                                                    : null
                                                            }`}
                                                        >
                                                            {agency.name}
                                                        </Text>
                                                    </Link>
                                                );
                                            })}
                                    </Col>

                                    <Link
                                        to={`/admin/invoices/${
                                            agencies.length > 0 &&
                                            agencies[0].id
                                        }`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <Activity
                                                selected={/\/invoices/.test(
                                                    location.pathname
                                                )}
                                            />

                                            <Text
                                                className={
                                                    /\/invoices/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Factures
                                            </Text>
                                        </Row>
                                    </Link>

                                    <Col>
                                        {agencies &&
                                            agencies.map((agency) => {
                                                return (
                                                    <>
                                                        <Link
                                                            to={`/admin/invoices/${agency.id}`}
                                                            style={{
                                                                width: "100%",
                                                                textDecorationLine:
                                                                    "none",
                                                            }}
                                                        >
                                                            <Text
                                                                className={`small ${
                                                                    /\/admin\/invoices/.test(
                                                                        location.pathname
                                                                    ) &&
                                                                    location.pathname.includes(
                                                                        agency.id
                                                                    )
                                                                        ? "selected-option"
                                                                        : null
                                                                }`}
                                                            >
                                                                {agency.name}
                                                            </Text>
                                                        </Link>
                                                    </>
                                                );
                                            })}
                                    </Col>

                                    <NavLink
                                        to={`/admin/notenprotocoles`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <BookMark
                                                selected={/\/admin\/notenprotocoles$/.test(
                                                    location.pathname
                                                )}
                                            />
                                            <Text
                                                className={
                                                    /\/admin\/notenprotocoles$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Notes & Protocoles
                                            </Text>
                                        </Row>
                                    </NavLink>

                                    <Link
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                            marginTop: "200px",
                                        }}
                                    >
                                        <Row>
                                            <Text></Text>
                                        </Row>
                                    </Link>
                                </ButtonsDiv>
                            );
                        } else if (user.role === 4) {
                            return (
                                <ButtonsDiv>
                                    <Link
                                        to={`/admin/invoices/${
                                            agencies.length > 0 &&
                                            agencies[0].id
                                        }`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <Activity
                                                selected={/\/invoices/.test(
                                                    location.pathname
                                                )}
                                            />

                                            <Text
                                                className={
                                                    /\/invoices/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Factures
                                            </Text>
                                        </Row>
                                    </Link>

                                    <Col>
                                        {agencies &&
                                            agencies.map((agency) => {
                                                return (
                                                    <>
                                                        <Link
                                                            to={`/admin/invoices/${agency.id}`}
                                                            style={{
                                                                width: "100%",
                                                                textDecorationLine:
                                                                    "none",
                                                            }}
                                                        >
                                                            <Text
                                                                className={`small ${
                                                                    /\/admin\/invoices/.test(
                                                                        location.pathname
                                                                    ) &&
                                                                    location.pathname.includes(
                                                                        agency.id
                                                                    )
                                                                        ? "selected-option"
                                                                        : null
                                                                }`}
                                                            >
                                                                {agency.name}
                                                            </Text>
                                                        </Link>
                                                    </>
                                                );
                                            })}
                                    </Col>
                                </ButtonsDiv>
                            );
                        } else {
                            return (
                                <ButtonsDiv>
                                    <NavLink
                                        to={`/home`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <Menu
                                                selected={/\/home$/.test(
                                                    location.pathname
                                                )}
                                            />
                                            <Text
                                                className={
                                                    /\/home$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Accueil
                                            </Text>
                                        </Row>
                                    </NavLink>

                                    <NavLink
                                        to={`/patients`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <Users
                                                selected={/\/patients$/.test(
                                                    location.pathname
                                                )}
                                            />
                                            <Text
                                                className={
                                                    /\/patients$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Patients
                                            </Text>
                                        </Row>
                                    </NavLink>

                                    {(() => {
                                        if (user.role == 7) {
                                            return (
                                                <>
                                                    {" "}
                                                    <Link
                                                        to={`/admin/agencies/${
                                                            agencies.length >
                                                                0 &&
                                                            agencies[0].id
                                                        }`}
                                                        style={{
                                                            width: "100%",
                                                            textDecorationLine:
                                                                "none",
                                                        }}
                                                    >
                                                        <Row>
                                                            <Activity
                                                                selected={/\/agencies/.test(
                                                                    location.pathname
                                                                )}
                                                            />

                                                            <Text
                                                                className={
                                                                    /\/agencies/.test(
                                                                        location.pathname
                                                                    )
                                                                        ? "selected"
                                                                        : null
                                                                }
                                                            >
                                                                Agences
                                                            </Text>
                                                        </Row>
                                                    </Link>
                                                    <Col>
                                                        {agencies
                                                            .filter(
                                                                (agency) => {
                                                                    return (
                                                                        agency.id ==
                                                                        user.agency_id
                                                                    );
                                                                }
                                                            )
                                                            .map((agency) => {
                                                                return (
                                                                    <>
                                                                        <Link
                                                                            to={`/admin/agencies/${agency.id}`}
                                                                            style={{
                                                                                width: "100%",
                                                                                textDecorationLine:
                                                                                    "none",
                                                                            }}
                                                                        >
                                                                            <Text
                                                                                className={`small ${
                                                                                    /\/admin\/agencies/.test(
                                                                                        location.pathname
                                                                                    ) &&
                                                                                    location.pathname.includes(
                                                                                        agency.id
                                                                                    )
                                                                                        ? "selected-option"
                                                                                        : null
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    agency.name
                                                                                }
                                                                            </Text>
                                                                        </Link>
                                                                    </>
                                                                );
                                                            })}
                                                    </Col>
                                                </>
                                            );
                                        }
                                    })()}

                                    <NavLink
                                        to={`/mnp`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <Notes
                                                selected={/\/mnp$/.test(
                                                    location.pathname
                                                )}
                                            />
                                            <Text
                                                className={
                                                    /\/mnp$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Notes particulières{" "}
                                                {mnp.filter(
                                                    (el) =>
                                                        el.special_note_status ===
                                                        1
                                                ).length ? (
                                                    <Badge
                                                        pill
                                                        bg="success"
                                                        style={{
                                                            background:
                                                                "rgb(255, 150, 141)",
                                                        }}
                                                    >
                                                        {
                                                            mnp.filter(
                                                                (el) =>
                                                                    el.special_note_status ===
                                                                    1
                                                            ).length
                                                        }
                                                    </Badge>
                                                ) : (
                                                    <></>
                                                )}
                                            </Text>
                                        </Row>
                                    </NavLink>

                                    <NavLink
                                        to={`/account`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <FingerPrint
                                                selected={/\/account$/.test(
                                                    location.pathname
                                                )}
                                            />
                                            <Text
                                                className={
                                                    /\/account$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Espace personnel
                                            </Text>
                                        </Row>
                                    </NavLink>

                                    <NavLink
                                        to={`/notenprotocoles`}
                                        style={{
                                            width: "100%",
                                            textDecorationLine: "none",
                                        }}
                                    >
                                        <Row>
                                            <BookMark
                                                selected={/\/notenprotocoles$/.test(
                                                    location.pathname
                                                )}
                                            />
                                            <Text
                                                className={
                                                    /\/notenprotocoles$/.test(
                                                        location.pathname
                                                    )
                                                        ? "selected"
                                                        : null
                                                }
                                            >
                                                Notes & Protocoles
                                            </Text>
                                        </Row>
                                    </NavLink>
                                </ButtonsDiv>
                            );
                        }
                    } else if (type === "patient") {
                        return (
                            <ButtonsDiv>
                                {menus.patient.map((item, index) => {
                                    let Icon = item.icon;

                                    let rtns = [];

                                    if (item.show({ client, user }) === false)
                                        return null;

                                    let name =
                                        typeof item.name === "string"
                                            ? item.name
                                            : item.name({ client, user });

                                    rtns.push(
                                        <Link
                                            to={item.path({ client, user })}
                                            style={{
                                                width: "100%",
                                                textDecorationLine: "none",
                                                marginTop: "15px",
                                            }}
                                        >
                                            <Row>
                                                <Icon
                                                    selected={item.selectedRegex.test(
                                                        location.pathname
                                                    )}
                                                />

                                                <Text
                                                    className={
                                                        item.selectedRegex.test(
                                                            location.pathname
                                                        )
                                                            ? "selected"
                                                            : null
                                                    }
                                                >
                                                    {name}
                                                </Text>
                                            </Row>
                                        </Link>
                                    );

                                    let tmpRtns = [];

                                    item.subs &&
                                        item.subs.map((sub, index) => {
                                            if (
                                                sub.show({ client, user }) ===
                                                false
                                            )
                                                return null;

                                            let subname =
                                                typeof sub.name === "string"
                                                    ? sub.name
                                                    : sub.name({
                                                          client,
                                                          user,
                                                      });

                                            tmpRtns.push(
                                                <Link
                                                    to={sub.path({
                                                        client,
                                                        user,
                                                    })}
                                                    style={{
                                                        width: "100%",
                                                        textDecorationLine:
                                                            "none",
                                                        fontWeight: sub.bold
                                                            ? "bold"
                                                            : "normal",
                                                    }}
                                                >
                                                    <Text
                                                        className={`small ${
                                                            sub.selectedRegex.test(
                                                                location.pathname
                                                            )
                                                                ? "selected-option"
                                                                : null
                                                        }`}
                                                        style={{
                                                            paddingLeft:
                                                                typeof subname ===
                                                                    "string" &&
                                                                subname.includes(
                                                                    "=== "
                                                                )
                                                                    ? "20px"
                                                                    : "10px",
                                                        }}
                                                    >
                                                        {typeof subname ===
                                                        "string"
                                                            ? subname.replace(
                                                                  "=== ",
                                                                  ""
                                                              )
                                                            : subname}
                                                    </Text>
                                                </Link>
                                            );
                                        });

                                    tmpRtns.length > 0 &&
                                        rtns.push(
                                            <Col
                                                style={{
                                                    width: "100%",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                {tmpRtns}
                                            </Col>
                                        );

                                    // item.subs && item.subs.length > 0 && rtns.push(<Col style={{
                                    //     height: "1px",
                                    //     background: "rgba(0, 0, 0, 0.1)",
                                    //     margin: "10px 0",
                                    //     marginTop: "20px",

                                    // }}></Col>)

                                    return rtns;
                                })}

                                <Link
                                    style={{
                                        width: "100%",
                                        textDecorationLine: "none",
                                        marginTop: "200px",
                                    }}
                                >
                                    <Row>
                                        <Text></Text>
                                    </Row>
                                </Link>
                            </ButtonsDiv>
                        );
                    } else if (type === "employee-account") {
                        return (
                            <ButtonsDiv>
                                <NavLink
                                    to={`/account`}
                                    style={{
                                        width: "100%",
                                        textDecorationLine: "none",
                                    }}
                                >
                                    <Row>
                                        <BookMark
                                            selected={/\/account$/.test(
                                                location.pathname
                                            )}
                                        />
                                        <Text
                                            className={
                                                /\/account$/.test(
                                                    location.pathname
                                                )
                                                    ? "selected"
                                                    : null
                                            }
                                        >
                                            Info personnelles
                                        </Text>
                                    </Row>
                                </NavLink>

                                <NavLink
                                    to={`/activity`}
                                    style={{
                                        width: "100%",
                                        textDecorationLine: "none",
                                    }}
                                >
                                    <Row>
                                        <Activity
                                            selected={/\/activity$/.test(
                                                location.pathname
                                            )}
                                        />
                                        <Text
                                            className={
                                                /\/activity$/.test(
                                                    location.pathname
                                                )
                                                    ? "selected"
                                                    : null
                                            }
                                        >
                                            Mon activité
                                        </Text>
                                    </Row>
                                </NavLink>
                            </ButtonsDiv>
                        );
                    }
                })()}

                <LogoutModal
                    show={disconnectModalVisible}
                    onHide={() => showDisconnectModal(false)}
                />
            </SubContainer>
        </Container>
    );
}

export default SideBar;
