import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import styled from "styled-components";
import { Container, Col, Image, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { v4 as uuidv4 } from "uuid";
import Loader from "react-js-loader";
import moment from "moment";
import AreaButton from "../../components/AreaButton";
import Button from "../../components/Button";
import Table from "../../components/Table";
import Input from "../../components/Input";
import PatientInformationsItem from "../../components/PatientInformationsItem";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";

import SessionHeader from "../../components/SessionHeader";
import DataTable from "react-data-table-component";

//api
import MyLaserApi from "../../api/MyLaserApi";
import Swal from "sweetalert2";

const Square = styled.div`
    position: absolute;
    width: 42px;
    height: 42px;
    z-index: 9;
    right: 10px;
    top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    &.selected {
        border-color: #f0b59475;
    }

    &.selected::after {
        position: absolute;
        content: "";
        width: 28px;
        height: 28px;
        background-color: #f0b59475;
        z-index: 9;
        right: 0;
        top: 6px;
        left: 6px;
        bottom: 0;
        border-radius: 5px;
    }
`;

const InSquare = styled.div``;

function AddSessionPaymentStepOne(props) {
    const history = useHistory();

    return (
        <Container fluid>
            <SessionHeader
                title="Ajout d’un paiement"
                text={"(Étape 1/2)"}
                goBack={() => history.goBack()}
            />

            <LaserSession />
        </Container>
    );
}

function LaserSession() {
    const user = useSelector((state) => state.user);

    const [typeOfView, setTypeOfView] = useState("treatments_of_day");

    const history = useHistory();
    const params = useParams();

    const [treatments, setTreatments] = useState([]);
    const [treatmentids, setTreatmentsids] = useState([]);
    const [checked, setChecked] = useState([]);

    let [employees, setEmployees] = useState([]);

    const [products, setProducts] = useState([]);

    let reload = () => {
        MyLaserApi.GetAgenciesClientsIdTreatments(params.id).then((res) => {
            setTreatmentsids(
                res.data
                    .filter((treatment) => treatment.type != "products")
                    .map((treatment) => treatment.id)
                    .sort()
            );

            setTreatments(
                res.data
                    .filter((treatment) => treatment.type != "products")
                    .map((treatment) => {
                        return {
                            ...treatment,
                            payed_price: treatment.payments.reduce(
                                (old, current) =>
                                    old + parseFloat(current.amount_income),
                                0
                            ),
                        };
                    })
                    .sort(function (a, b) {
                        return a.id - b.id;
                    })
            );

            setProducts(
                res.data
                    .filter((treatment) => treatment.type == "products")
                    .map((treatment) => {
                        return {
                            ...treatment,
                            payed_price: treatment.payments.reduce(
                                (old, current) =>
                                    old + parseFloat(current.amount_income),
                                0
                            ),
                        };
                    })
                    .sort(function (a, b) {
                        return a.id - b.id;
                    })
            );
        });
    };

    useEffect(() => {
        reload();

        MyLaserApi.call({ url: "/employees" }).then(({ data }) => {
            setEmployees(data);
        });
    }, []);

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div style={{ width: "100%" }}>
                        {products.filter(
                            (treatment) =>
                                treatment.payed_price <
                                treatment.forced_price -
                                    treatment.discounts.reduce(
                                        (p, c) => p + parseFloat(c.amount),
                                        0
                                    )
                        ).length != 0 && (
                            <div
                                class="card text-center"
                                style={{ width: "100%", marginBottom: "10px" }}
                            >
                                <div class="card-header">
                                    <ul class="nav nav-tabs card-header-tabs">
                                        <li class="nav-item">
                                            <a
                                                class={`nav-link ${
                                                    typeOfView == "all"
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    setTypeOfView("all")
                                                }
                                            >
                                                Tous les achats à régler (
                                                {products.length})
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    class="card-body"
                                    style={{
                                        paddingTop: "15px",
                                        position: "relative",
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {(() => {
                                        let rts = products.map((treatment) => {
                                            let produits = JSON.parse(
                                                treatment.sessions[0].data
                                            );

                                            if (checked.length != 0) {
                                                if (
                                                    checked[0].type !=
                                                    treatment.type
                                                ) {
                                                    return;
                                                }
                                            }

                                            return (
                                                <div
                                                    key={treatment.id}
                                                    class="card mb-3"
                                                    style={{
                                                        flexBasis: "32%",
                                                        borderColor:
                                                            !!checked.find(
                                                                (el) =>
                                                                    el.id ===
                                                                    treatment.id
                                                            )
                                                                ? "#F0B594"
                                                                : "rgba(108,117,125, 0.1)",
                                                        marginLeft: "5px",
                                                        // borderWidth: "2px",
                                                        paddingLeft: "10px",
                                                    }}
                                                >
                                                    <Square
                                                        className={
                                                            !!checked.find(
                                                                (el) =>
                                                                    el.id ===
                                                                    treatment.id
                                                            )
                                                                ? "selected"
                                                                : ""
                                                        }
                                                        onClick={(e) => {
                                                            if (
                                                                checked.find(
                                                                    (el) =>
                                                                        el.id ===
                                                                        treatment.id
                                                                )
                                                            ) {
                                                                setChecked(
                                                                    checked.filter(
                                                                        (el) =>
                                                                            el.id !=
                                                                            treatment.id
                                                                    )
                                                                );
                                                            } else {
                                                                setChecked([
                                                                    ...checked,
                                                                    treatment,
                                                                ]);
                                                            }
                                                        }}
                                                    />
                                                    <div
                                                        class="card-body text-secondary"
                                                        style={{
                                                            paddingTop: "5px",
                                                            paddingBottom:
                                                                "5px",
                                                            textAlign: "left",
                                                            padding:
                                                                "1rem 0rem",
                                                        }}
                                                    >
                                                        <b>Achat</b> <br />{" "}
                                                        Achat de
                                                        {" " +
                                                            produits.length}{" "}
                                                        produits <br />
                                                        {treatment.payed_price.toFixed(
                                                            2
                                                        )}
                                                        {global.currencySymbol}{" "}
                                                        payé sur{" "}
                                                        {(
                                                            treatment.forced_price -
                                                            treatment.discounts.reduce(
                                                                (p, c) =>
                                                                    p +
                                                                    parseFloat(
                                                                        c.amount
                                                                    ),
                                                                0
                                                            )
                                                        ).toFixed(2)}{" "}
                                                        {global.currencySymbol}
                                                        {treatment.discounts
                                                            .length > 0 &&
                                                            treatment.discounts.map(
                                                                (d) => [
                                                                    <br />,
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                "12px",
                                                                        }}
                                                                    >
                                                                        Remise
                                                                        Exceptionnelle
                                                                        (
                                                                        {
                                                                            d.reason
                                                                        }
                                                                        ){" "}
                                                                        <i>
                                                                            de{" "}
                                                                            {d.amount.toFixed(
                                                                                2
                                                                            )}
                                                                            {
                                                                                global.currencySymbol
                                                                            }{" "}
                                                                            soit{" "}
                                                                            {d.percentage.toFixed(
                                                                                2
                                                                            )}
                                                                            %
                                                                        </i>
                                                                        {[
                                                                            1,
                                                                            2,
                                                                            7,
                                                                        ].includes(
                                                                            user.role
                                                                        ) && (
                                                                            <span
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation();

                                                                                    Swal.fire(
                                                                                        {
                                                                                            title: "Êtes-vous sûr ?",
                                                                                            text: "Vous ne pourrez pas revenir en arrière !",
                                                                                            icon: "warning",
                                                                                            showCancelButton: true,
                                                                                            cancelButtonText:
                                                                                                "Annuler",
                                                                                            confirmButtonColor:
                                                                                                "#f0b594",
                                                                                            cancelButtonColor:
                                                                                                "#6b7389",
                                                                                            confirmButtonText:
                                                                                                "Oui, supprimer !",
                                                                                        }
                                                                                    ).then(
                                                                                        (
                                                                                            result
                                                                                        ) => {
                                                                                            if (
                                                                                                result.isConfirmed
                                                                                            ) {
                                                                                                MyLaserApi.DeleteTreatmentDiscount(
                                                                                                    treatment.id,
                                                                                                    d.id
                                                                                                ).then(
                                                                                                    (
                                                                                                        res
                                                                                                    ) => {
                                                                                                        reload();
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    marginLeft:
                                                                                        "15px",
                                                                                    color: "rgb(255, 150, 141)",
                                                                                }}
                                                                            >
                                                                                Supprimer
                                                                                la
                                                                                remise
                                                                            </span>
                                                                        )}
                                                                    </span>,
                                                                ]
                                                            )}
                                                        <br />
                                                        {[1, 2, 7].includes(
                                                            user.role
                                                        ) && (
                                                            <span
                                                                style={{
                                                                    width: "auto",
                                                                    color: "rgb(136, 194, 211)",
                                                                    fontSize:
                                                                        "14px",
                                                                }}
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: "Remise",
                                                                        text: `De combien d'${
                                                                            window.currencySymbol ===
                                                                            "€"
                                                                                ? "euros"
                                                                                : "dinars"
                                                                        } souhaitez vous faire la remise ?`,
                                                                        // input: 'text',
                                                                        showCancelButton: true,
                                                                        confirmButtonText:
                                                                            "Calculer la remise",
                                                                        showLoaderOnConfirm: true,
                                                                        customClass:
                                                                            "swal-wide",
                                                                        html: `Veuillez entrer le montant et la raison de la remise.<br />
                                        <div class="option-wrapper">

                                            <input type="radio" name="swal-input3" value="percentage" id="option-1" checked>

                                            <label for="option-1" class="option option-1">
                                                <span>% Pourcentage</span>
                                                </label>

                                            <input type="radio" name="swal-input3" value="euros" id="option-2">

                                            <label for="option-2" class="option option-2">
                                                <span>${
                                                    global.currencySymbol
                                                } ${
                                                                            window.currencySymbol ===
                                                                            "€"
                                                                                ? "Euros"
                                                                                : "Dinars"
                                                                        }</span>
                                            </label>

                                        </div>
                                        
                                        <input id="swal-input1" class="swal2-input" placeholder="Montant de la remise en % ou ${
                                            global.currencySymbol
                                        }">
                                        <input id="swal-input2" class="swal2-input" placeholder="Raison de la remise">`,
                                                                        preConfirm:
                                                                            (
                                                                                data
                                                                            ) => {
                                                                                let amount =
                                                                                    document
                                                                                        .querySelector(
                                                                                            "#swal-input1"
                                                                                        )
                                                                                        .value.replace(
                                                                                            ",",
                                                                                            "."
                                                                                        );
                                                                                let reason =
                                                                                    document.querySelector(
                                                                                        "#swal-input2"
                                                                                    ).value;

                                                                                if (
                                                                                    isNaN(
                                                                                        amount
                                                                                    )
                                                                                ) {
                                                                                    Swal.showValidationMessage(
                                                                                        `Erreur: La remise n'est pas au bon format, n'ajoutez pas de %.`
                                                                                    );
                                                                                } else {
                                                                                    if (
                                                                                        amount ==
                                                                                        0
                                                                                    ) {
                                                                                        Swal.showValidationMessage(
                                                                                            `Erreur: La remise doit être au dessus de 0.`
                                                                                        );
                                                                                    }
                                                                                }

                                                                                if (
                                                                                    reason ==
                                                                                    ""
                                                                                ) {
                                                                                    Swal.showValidationMessage(
                                                                                        `Erreur: La raison doit être spécifié.`
                                                                                    );
                                                                                }
                                                                            },
                                                                        allowOutsideClick:
                                                                            () =>
                                                                                !Swal.isLoading(),
                                                                    }).then(
                                                                        (
                                                                            result
                                                                        ) => {
                                                                            if (
                                                                                result.isConfirmed
                                                                            ) {
                                                                                let percent,
                                                                                    reduction,
                                                                                    amount;
                                                                                let reason =
                                                                                    document.querySelector(
                                                                                        "#swal-input2"
                                                                                    ).value;
                                                                                let type =
                                                                                    document.querySelector(
                                                                                        "input[name='swal-input3']:checked"
                                                                                    ).value;

                                                                                if (
                                                                                    type ==
                                                                                    "percentage"
                                                                                ) {
                                                                                    percent =
                                                                                        document
                                                                                            .querySelector(
                                                                                                "#swal-input1"
                                                                                            )
                                                                                            .value.replace(
                                                                                                ",",
                                                                                                "."
                                                                                            );
                                                                                    reduction =
                                                                                        (
                                                                                            treatment.forced_price *
                                                                                            (percent /
                                                                                                100)
                                                                                        ).toFixed(
                                                                                            2
                                                                                        );
                                                                                } else {
                                                                                    reduction =
                                                                                        document
                                                                                            .querySelector(
                                                                                                "#swal-input1"
                                                                                            )
                                                                                            .value.replace(
                                                                                                ",",
                                                                                                "."
                                                                                            );
                                                                                    percent =
                                                                                        Math.abs(
                                                                                            ((treatment.forced_price -
                                                                                                reduction -
                                                                                                treatment.forced_price) /
                                                                                                treatment.forced_price) *
                                                                                                100
                                                                                        ).toFixed(
                                                                                            2
                                                                                        );
                                                                                }

                                                                                if (
                                                                                    reduction -
                                                                                        treatment.forced_price ==
                                                                                    0
                                                                                ) {
                                                                                    percent = 100;
                                                                                }

                                                                                if (
                                                                                    percent >
                                                                                    100
                                                                                ) {
                                                                                    return Swal.fire(
                                                                                        {
                                                                                            icon: "error",
                                                                                            title: "Erreur",
                                                                                            text: "La remise ne peut pas être supérieur au prix du traitement.",
                                                                                        }
                                                                                    );
                                                                                }

                                                                                Swal.fire(
                                                                                    {
                                                                                        title: "Remise",
                                                                                        text: `Le montant de la remise sera de ${reduction}${global.currencySymbol} soit ${percent} % .`,
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText:
                                                                                            "Transmettre la remise",
                                                                                    }
                                                                                ).then(
                                                                                    (
                                                                                        result2
                                                                                    ) => {
                                                                                        if (
                                                                                            result2.isConfirmed
                                                                                        ) {
                                                                                            MyLaserApi.treatmentAddDiscount(
                                                                                                treatment.id,
                                                                                                {
                                                                                                    amount: reduction,
                                                                                                    reason: reason,
                                                                                                    percentage:
                                                                                                        percent,
                                                                                                }
                                                                                            ).then(
                                                                                                () => {
                                                                                                    reload();
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Transmettre une
                                                                Remise
                                                                Exceptionnel
                                                            </span>
                                                        )}
                                                        <br />
                                                        <br />
                                                        <p
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    checked.find(
                                                                        (el) =>
                                                                            el.id ===
                                                                            treatment.id
                                                                    )
                                                                ) {
                                                                    setChecked(
                                                                        checked.filter(
                                                                            (
                                                                                el
                                                                            ) =>
                                                                                el.id !=
                                                                                treatment.id
                                                                        )
                                                                    );
                                                                } else {
                                                                    setChecked([
                                                                        ...checked,
                                                                        treatment,
                                                                    ]);
                                                                }
                                                            }}
                                                        >
                                                            {treatment.sessions.map(
                                                                (subsingle) => {
                                                                    let d =
                                                                        moment(
                                                                            subsingle.date
                                                                        );
                                                                    let date =
                                                                        [];

                                                                    date.push(
                                                                        `${d.format(
                                                                            "LLLL"
                                                                        )}`
                                                                    );

                                                                    produits.map(
                                                                        (
                                                                            product
                                                                        ) => {
                                                                            date.push(
                                                                                <span>
                                                                                    <br />
                                                                                    📦
                                                                                    |
                                                                                    {" " +
                                                                                        product.name}
                                                                                </span>
                                                                            );
                                                                        }
                                                                    );

                                                                    return (
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    "15px",
                                                                                textTransform:
                                                                                    "capitalize",
                                                                            }}
                                                                        >
                                                                            {
                                                                                date
                                                                            }{" "}
                                                                            <br />
                                                                        </span>
                                                                    );
                                                                }
                                                            )}
                                                            <hr />

                                                            {(() => {
                                                                let tmpArr = [
                                                                    ...treatment.sessions,
                                                                ].filter(
                                                                    (el) =>
                                                                        el.employee_id
                                                                );
                                                                let employee =
                                                                    null;

                                                                if (
                                                                    tmpArr.length >
                                                                    0
                                                                ) {
                                                                    tmpArr.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            new Date(
                                                                                a.date
                                                                            ) -
                                                                            new Date(
                                                                                b.date
                                                                            )
                                                                    );

                                                                    employee =
                                                                        employees.find(
                                                                            (
                                                                                el
                                                                            ) =>
                                                                                el.id ==
                                                                                tmpArr[0]
                                                                                    .employee_id
                                                                        );
                                                                }

                                                                console.log(
                                                                    employee
                                                                );

                                                                return (
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                "15px",
                                                                        }}
                                                                    >
                                                                        👤 Vente
                                                                        attribué
                                                                        à{" "}
                                                                        {
                                                                            employee.firstname
                                                                        }{" "}
                                                                        {
                                                                            employee.lastname
                                                                        }{" "}
                                                                        <br />
                                                                    </span>
                                                                );
                                                            })()}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        });

                                        return rts;
                                    })()}
                                </div>
                            </div>
                        )}

                        <div class="card text-center" style={{ width: "100%" }}>
                            <div class="card-header">
                                <ul class="nav nav-tabs card-header-tabs">
                                    <li class="nav-item">
                                        <a
                                            class={`nav-link ${
                                                typeOfView == "all"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() => setTypeOfView("all")}
                                        >
                                            Tous les traitements (
                                            {treatments.length})
                                        </a>
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            class={`nav-link ${
                                                typeOfView ==
                                                "treatments_of_day"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setTypeOfView(
                                                    "treatments_of_day"
                                                )
                                            }
                                        >
                                            Traitements du jour (
                                            {
                                                treatments.filter(
                                                    (treatment) =>
                                                        treatment.sessions.filter(
                                                            (el) =>
                                                                moment(
                                                                    el.date
                                                                ).isSame(
                                                                    moment(),
                                                                    "day"
                                                                )
                                                        ).length > 0
                                                ).length
                                            }
                                            )
                                        </a>
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            class={`nav-link ${
                                                typeOfView ==
                                                "treatments_without_paid"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setTypeOfView(
                                                    "treatments_without_paid"
                                                )
                                            }
                                        >
                                            Traitements sans payement (
                                            {
                                                treatments.filter(
                                                    (treatment) =>
                                                        treatment.payed_price ==
                                                        0
                                                ).length
                                            }
                                            )
                                        </a>
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            class={`nav-link ${
                                                typeOfView ==
                                                "treatments_partial_paid"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setTypeOfView(
                                                    "treatments_partial_paid"
                                                )
                                            }
                                        >
                                            Traitements partiellement payé (
                                            {
                                                treatments.filter(
                                                    (treatment) =>
                                                        treatment.payed_price !=
                                                            0 &&
                                                        treatment.payed_price <
                                                            treatment.forced_price -
                                                                treatment.discounts.reduce(
                                                                    (p, c) =>
                                                                        p +
                                                                        parseFloat(
                                                                            c.amount
                                                                        ),
                                                                    0
                                                                )
                                                ).length
                                            }
                                            )
                                        </a>
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            class={`nav-link ${
                                                typeOfView ==
                                                "treatments_fully_paid"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setTypeOfView(
                                                    "treatments_fully_paid"
                                                )
                                            }
                                        >
                                            Traitements completement payé (
                                            {
                                                treatments.filter(
                                                    (treatment) =>
                                                        treatment.payed_price >=
                                                        treatment.forced_price -
                                                            treatment.discounts.reduce(
                                                                (p, c) =>
                                                                    p +
                                                                    parseFloat(
                                                                        c.amount
                                                                    ),
                                                                0
                                                            )
                                                ).length
                                            }
                                            )
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="card-body"
                                style={{
                                    paddingTop: "15px",
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                {(() => {
                                    let rts = treatments
                                        .filter(
                                            (treatment) =>
                                                treatment.sessions.length != 0
                                        )
                                        .map((treatment) => {
                                            if (
                                                typeOfView ==
                                                "treatments_fully_paid"
                                            ) {
                                                if (
                                                    treatment.payed_price <
                                                    treatment.forced_price -
                                                        treatment.discounts.reduce(
                                                            (p, c) =>
                                                                p +
                                                                parseFloat(
                                                                    c.amount
                                                                ),
                                                            0
                                                        )
                                                ) {
                                                    return;
                                                }
                                            }

                                            if (
                                                typeOfView ==
                                                "treatments_without_paid"
                                            ) {
                                                if (
                                                    treatment.payed_price != 0
                                                ) {
                                                    return;
                                                }
                                            }

                                            if (
                                                typeOfView ==
                                                "treatments_partial_paid"
                                            ) {
                                                if (
                                                    treatment.payed_price ==
                                                        0 ||
                                                    treatment.payed_price >=
                                                        treatment.forced_price -
                                                            treatment.discounts.reduce(
                                                                (p, c) =>
                                                                    p +
                                                                    parseFloat(
                                                                        c.amount
                                                                    ),
                                                                0
                                                            )
                                                ) {
                                                    return;
                                                }
                                            }

                                            if (
                                                typeOfView ==
                                                "treatments_of_day"
                                            ) {
                                                if (
                                                    treatment.sessions.filter(
                                                        (el) =>
                                                            moment(
                                                                el.date
                                                            ).isSame(
                                                                moment(),
                                                                "day"
                                                            )
                                                    ).length == 0
                                                ) {
                                                    return;
                                                }
                                            }

                                            if (checked.length != 0) {
                                                if (
                                                    checked[0].type !=
                                                    treatment.type
                                                ) {
                                                    return;
                                                }
                                            }

                                            return (
                                                <div
                                                    key={treatment.id}
                                                    class="card mb-3"
                                                    style={{
                                                        flexBasis: "32%",
                                                        borderColor:
                                                            !!checked.find(
                                                                (el) =>
                                                                    el.id ===
                                                                    treatment.id
                                                            )
                                                                ? "#F0B594"
                                                                : "rgba(108,117,125, 0.1)",
                                                        marginLeft: "5px",
                                                        // borderWidth: "2px",
                                                        paddingLeft: "10px",
                                                    }}
                                                >
                                                    <Square
                                                        className={
                                                            !!checked.find(
                                                                (el) =>
                                                                    el.id ===
                                                                    treatment.id
                                                            )
                                                                ? "selected"
                                                                : ""
                                                        }
                                                        onClick={(e) => {
                                                            if (
                                                                checked.find(
                                                                    (el) =>
                                                                        el.id ===
                                                                        treatment.id
                                                                )
                                                            ) {
                                                                setChecked(
                                                                    checked.filter(
                                                                        (el) =>
                                                                            el.id !=
                                                                            treatment.id
                                                                    )
                                                                );
                                                            } else {
                                                                setChecked([
                                                                    ...checked,
                                                                    treatment,
                                                                ]);
                                                            }
                                                        }}
                                                    />
                                                    <div
                                                        class="card-body text-secondary"
                                                        style={{
                                                            paddingTop: "5px",
                                                            paddingBottom:
                                                                "5px",
                                                            textAlign: "left",
                                                            padding:
                                                                "1rem 0rem",
                                                        }}
                                                    >
                                                        <b>
                                                            Traitement n°
                                                            {treatmentids.findIndex(
                                                                (id) =>
                                                                    treatment.id ==
                                                                    id
                                                            ) + 1}
                                                        </b>{" "}
                                                        <br />{" "}
                                                        {treatment.type_human}{" "}
                                                        de{" "}
                                                        {
                                                            treatment.sessions
                                                                .length
                                                        }{" "}
                                                        zones <br />
                                                        {treatment.payed_price.toFixed(
                                                            2
                                                        )}
                                                        {global.currencySymbol}{" "}
                                                        payé sur{" "}
                                                        {(
                                                            treatment.forced_price -
                                                            treatment.discounts.reduce(
                                                                (p, c) =>
                                                                    p +
                                                                    parseFloat(
                                                                        c.amount
                                                                    ),
                                                                0
                                                            )
                                                        ).toFixed(2)}{" "}
                                                        {global.currencySymbol}
                                                        {treatment.discounts
                                                            .length > 0 &&
                                                            treatment.discounts.map(
                                                                (d) => [
                                                                    <br />,
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                "12px",
                                                                        }}
                                                                    >
                                                                        Remise
                                                                        Exceptionnelle
                                                                        (
                                                                        {
                                                                            d.reason
                                                                        }
                                                                        ){" "}
                                                                        <i>
                                                                            de{" "}
                                                                            {d.amount.toFixed(
                                                                                2
                                                                            )}
                                                                            {
                                                                                global.currencySymbol
                                                                            }{" "}
                                                                            soit{" "}
                                                                            {d.percentage.toFixed(
                                                                                2
                                                                            )}
                                                                            %
                                                                        </i>
                                                                        {[
                                                                            1,
                                                                            2,
                                                                            7,
                                                                        ].includes(
                                                                            user.role
                                                                        ) && (
                                                                            <span
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation();

                                                                                    Swal.fire(
                                                                                        {
                                                                                            title: "Êtes-vous sûr ?",
                                                                                            text: "Vous ne pourrez pas revenir en arrière !",
                                                                                            icon: "warning",
                                                                                            showCancelButton: true,
                                                                                            cancelButtonText:
                                                                                                "Annuler",
                                                                                            confirmButtonColor:
                                                                                                "#f0b594",
                                                                                            cancelButtonColor:
                                                                                                "#6b7389",
                                                                                            confirmButtonText:
                                                                                                "Oui, supprimer !",
                                                                                        }
                                                                                    ).then(
                                                                                        (
                                                                                            result
                                                                                        ) => {
                                                                                            if (
                                                                                                result.isConfirmed
                                                                                            ) {
                                                                                                MyLaserApi.DeleteTreatmentDiscount(
                                                                                                    treatment.id,
                                                                                                    d.id
                                                                                                ).then(
                                                                                                    (
                                                                                                        res
                                                                                                    ) => {
                                                                                                        reload();
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    marginLeft:
                                                                                        "15px",
                                                                                    color: "rgb(255, 150, 141)",
                                                                                }}
                                                                            >
                                                                                Supprimer
                                                                                la
                                                                                remise
                                                                            </span>
                                                                        )}
                                                                    </span>,
                                                                ]
                                                            )}
                                                        <br />
                                                        {[1, 2, 7].includes(
                                                            user.role
                                                        ) && (
                                                            <span
                                                                style={{
                                                                    width: "auto",
                                                                    color: "rgb(136, 194, 211)",
                                                                    fontSize:
                                                                        "14px",
                                                                }}
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: "Remise",
                                                                        text: `De combien d'${
                                                                            window.currencySymbol ===
                                                                            "€"
                                                                                ? "euros"
                                                                                : "dinars"
                                                                        } souhaitez vous faire la remise ?`,
                                                                        // input: 'text',
                                                                        showCancelButton: true,
                                                                        confirmButtonText:
                                                                            "Calculer la remise",
                                                                        showLoaderOnConfirm: true,
                                                                        customClass:
                                                                            "swal-wide",
                                                                        html: `Veuillez entrer le montant et la raison de la remise.<br />
                                                                <div class="option-wrapper">

                                                                    <input type="radio" name="swal-input3" value="percentage" id="option-1" checked>

                                                                    <label for="option-1" class="option option-1">
                                                                        <span>% Pourcentage</span>
                                                                        </label>

                                                                    <input type="radio" name="swal-input3" value="euros" id="option-2">

                                                                    <label for="option-2" class="option option-2">
                                                                        <span>${
                                                                            global.currencySymbol
                                                                        } ${
                                                                            window.currencySymbol ===
                                                                            "€"
                                                                                ? "Euros"
                                                                                : "Dinars"
                                                                        }</span>
                                                                    </label>

                                                                </div>
                                                                
                                                                <input id="swal-input1" class="swal2-input" placeholder="Montant de la remise en % ou ${
                                                                    global.currencySymbol
                                                                }">
                                                                <input id="swal-input2" class="swal2-input" placeholder="Raison de la remise">`,
                                                                        preConfirm:
                                                                            (
                                                                                data
                                                                            ) => {
                                                                                let amount =
                                                                                    document
                                                                                        .querySelector(
                                                                                            "#swal-input1"
                                                                                        )
                                                                                        .value.replace(
                                                                                            ",",
                                                                                            "."
                                                                                        );
                                                                                let reason =
                                                                                    document.querySelector(
                                                                                        "#swal-input2"
                                                                                    ).value;

                                                                                if (
                                                                                    isNaN(
                                                                                        amount
                                                                                    )
                                                                                ) {
                                                                                    Swal.showValidationMessage(
                                                                                        `Erreur: La remise n'est pas au bon format, n'ajoutez pas de %.`
                                                                                    );
                                                                                } else {
                                                                                    if (
                                                                                        amount ==
                                                                                        0
                                                                                    ) {
                                                                                        Swal.showValidationMessage(
                                                                                            `Erreur: La remise doit être au dessus de 0.`
                                                                                        );
                                                                                    }
                                                                                }

                                                                                if (
                                                                                    reason ==
                                                                                    ""
                                                                                ) {
                                                                                    Swal.showValidationMessage(
                                                                                        `Erreur: La raison doit être spécifié.`
                                                                                    );
                                                                                }
                                                                            },
                                                                        allowOutsideClick:
                                                                            () =>
                                                                                !Swal.isLoading(),
                                                                    }).then(
                                                                        (
                                                                            result
                                                                        ) => {
                                                                            if (
                                                                                result.isConfirmed
                                                                            ) {
                                                                                let percent,
                                                                                    reduction,
                                                                                    amount;
                                                                                let reason =
                                                                                    document.querySelector(
                                                                                        "#swal-input2"
                                                                                    ).value;
                                                                                let type =
                                                                                    document.querySelector(
                                                                                        "input[name='swal-input3']:checked"
                                                                                    ).value;

                                                                                if (
                                                                                    type ==
                                                                                    "percentage"
                                                                                ) {
                                                                                    percent =
                                                                                        document
                                                                                            .querySelector(
                                                                                                "#swal-input1"
                                                                                            )
                                                                                            .value.replace(
                                                                                                ",",
                                                                                                "."
                                                                                            );
                                                                                    reduction =
                                                                                        (
                                                                                            treatment.forced_price *
                                                                                            (percent /
                                                                                                100)
                                                                                        ).toFixed(
                                                                                            2
                                                                                        );
                                                                                } else {
                                                                                    reduction =
                                                                                        document
                                                                                            .querySelector(
                                                                                                "#swal-input1"
                                                                                            )
                                                                                            .value.replace(
                                                                                                ",",
                                                                                                "."
                                                                                            );
                                                                                    percent =
                                                                                        Math.abs(
                                                                                            ((treatment.forced_price -
                                                                                                reduction -
                                                                                                treatment.forced_price) /
                                                                                                treatment.forced_price) *
                                                                                                100
                                                                                        ).toFixed(
                                                                                            2
                                                                                        );
                                                                                }

                                                                                if (
                                                                                    reduction -
                                                                                        treatment.forced_price ==
                                                                                    0
                                                                                ) {
                                                                                    percent = 100;
                                                                                }

                                                                                if (
                                                                                    percent >
                                                                                    100
                                                                                ) {
                                                                                    return Swal.fire(
                                                                                        {
                                                                                            icon: "error",
                                                                                            title: "Erreur",
                                                                                            text: "La remise ne peut pas être supérieur au prix du traitement.",
                                                                                        }
                                                                                    );
                                                                                }

                                                                                Swal.fire(
                                                                                    {
                                                                                        title: "Remise",
                                                                                        text: `Le montant de la remise sera de ${reduction}${global.currencySymbol} soit ${percent} % .`,
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText:
                                                                                            "Transmettre la remise",
                                                                                    }
                                                                                ).then(
                                                                                    (
                                                                                        result2
                                                                                    ) => {
                                                                                        if (
                                                                                            result2.isConfirmed
                                                                                        ) {
                                                                                            MyLaserApi.treatmentAddDiscount(
                                                                                                treatment.id,
                                                                                                {
                                                                                                    amount: reduction,
                                                                                                    reason: reason,
                                                                                                    percentage:
                                                                                                        percent,
                                                                                                }
                                                                                            ).then(
                                                                                                () => {
                                                                                                    reload();
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Transmettre une
                                                                Remise
                                                                Exceptionnel
                                                            </span>
                                                        )}
                                                        <br />
                                                        <br />
                                                        <p
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    checked.find(
                                                                        (el) =>
                                                                            el.id ===
                                                                            treatment.id
                                                                    )
                                                                ) {
                                                                    setChecked(
                                                                        checked.filter(
                                                                            (
                                                                                el
                                                                            ) =>
                                                                                el.id !=
                                                                                treatment.id
                                                                        )
                                                                    );
                                                                } else {
                                                                    setChecked([
                                                                        ...checked,
                                                                        treatment,
                                                                    ]);
                                                                }
                                                            }}
                                                        >
                                                            {treatment.sessions.map(
                                                                (subsingle) => {
                                                                    let d =
                                                                        moment(
                                                                            subsingle.date
                                                                        );
                                                                    let date =
                                                                        "";

                                                                    if (
                                                                        d.year() ==
                                                                        1970
                                                                    ) {
                                                                        date += `⌛ ${subsingle.area}: pas encore effectuée`;
                                                                    } else {
                                                                        date += `✅ ${
                                                                            subsingle.area
                                                                        }: ${d.format(
                                                                            "LLLL"
                                                                        )}`;
                                                                    }

                                                                    return (
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    "15px",
                                                                                marginLeft:
                                                                                    "5px",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {
                                                                                date
                                                                            }{" "}
                                                                            <br />
                                                                        </span>
                                                                    );
                                                                }
                                                            )}
                                                            <hr />
                                                            {(() => {
                                                                let tmpArr = [
                                                                    ...treatment.sessions,
                                                                ].filter(
                                                                    (el) =>
                                                                        el.employee_id
                                                                );
                                                                let employee =
                                                                    null;

                                                                if (
                                                                    tmpArr.length >
                                                                    0
                                                                ) {
                                                                    tmpArr.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            new Date(
                                                                                a.date
                                                                            ) -
                                                                            new Date(
                                                                                b.date
                                                                            )
                                                                    );

                                                                    employee =
                                                                        employees.find(
                                                                            (
                                                                                el
                                                                            ) =>
                                                                                el.id ==
                                                                                tmpArr[0]
                                                                                    .employee_id
                                                                        );
                                                                }

                                                                if (employee) {
                                                                    return (
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    "15px",
                                                                                marginLeft:
                                                                                    "5px",
                                                                            }}
                                                                        >
                                                                            👤
                                                                            Préstation
                                                                            attribué
                                                                            à{" "}
                                                                            {
                                                                                employee.firstname
                                                                            }{" "}
                                                                            {
                                                                                employee.lastname
                                                                            }{" "}
                                                                            <br />
                                                                        </span>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    "15px",
                                                                                marginLeft:
                                                                                    "5px",
                                                                            }}
                                                                        >
                                                                            👤
                                                                            Aucun
                                                                            praticien
                                                                            n'a
                                                                            encore
                                                                            effectué
                                                                            ce
                                                                            traitement.{" "}
                                                                            <br />
                                                                        </span>
                                                                    );
                                                                }
                                                            })()}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        });

                                    if (
                                        rts.filter(
                                            (element) => element !== undefined
                                        ).length == 0
                                    ) {
                                        return "Il n'y a aucun traitement à afficher.";
                                    }

                                    return rts;
                                })()}
                            </div>
                        </div>

                        <Button
                            style={{
                                width: "300px",
                                position: "fixed",
                                bottom: 25,
                                right: 25,
                            }}
                            disabled={checked.length == 0}
                            onClick={() => {
                                console.log(checked);

                                history.push(
                                    `/patients/${params.id}/payment/add-price`,
                                    {
                                        checked: checked,
                                    }
                                );
                            }}
                        >
                            Valider les traitements
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AddSessionPaymentStepOne;
