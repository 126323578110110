import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useParams,
} from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import * as AWS from "aws-sdk";
import moment from "moment";
import "moment/locale/fr";
import fr from "date-fns/locale/fr";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//components
import Header from "./components/Header";
import SideBar from "./components/SideBar";

//screens
import Home from "./screens/home/Home";
import Patients from "./screens/patients/Patients";
import NotesAndProtocoles from "./screens/NotesAndProtocoles";
import FollowAHInjection from "./screens/patients/Sessions/FollowAHInjection";

import PatientInformations from "./screens/patients/PatientInformations";

import ListSession from "./screens/patients/Sessions/ListSession";

import PatientTreatmentLaserNotesList from "./screens/patients/Sessions/Notes/List";
import PatientTreatmentLaserNotesSingle from "./screens/patients/Sessions/Notes/Single";
import CreateSession from "./screens/patients/Sessions/CreateSession";
import UpdateSession from "./screens/patients/Sessions/UpdateSession";

import Payment from "./screens/patients/Payment";
import MyAccount from "./screens/account/MyAccount";
import MyActivity from "./screens/account/MyActivity";
import TreatmentConsent from "./screens/patients/TreatmentConsent";
import ConsentCreate from "./screens/patients/ConsentsV2/ConsentCreate";
// import AddSessionDetails from "./screens/patients/AddSessionDetails";
import AddSessionPaymentStepTwo from "./screens/patients/AddSessionPaymentStepTwo";
import Quotation from "./screens/patients/Quotation";
import AddSessionPaymentStepOne from "./screens/patients/AddSessionPaymentStepOne";
import ConsentShow from "./screens/home/Consents/ConsentShow";
import ConsentSign from "./screens/home/Consents/ConsentSign";

import PatientQuotationFromType from "./screens/patients/quotations/QuotationFromType";
import PatientCreateQuotationFromType from "./screens/patients/quotations/CreateQuotationFromType";
import PatientCreateQuotationFromTypeMA from "./screens/patients/quotations/CreateQuotationFromType.ma";
import PatientCreateQuotationValidateFromType from "./screens/patients/quotations/CreateQuotationValidateFromType";

import PatientProducts from "./screens/patients/Products";

import PatientConsentAdd from "./screens/patients/PatientConsentAdd";

//admin
import AdminEmployees from "./screens/admin/employees/Employees";
import AdminSessions from "./screens/admin/Sessions";
import AdminInvoices from "./screens/admin/Invoices";
import AdminAgency from "./screens/admin/Agency";
import AdminAgencies from "./screens/admin/Agencies";

import AdminOuss from "./screens/admin/Oussama";

import UpdateSessionPayment from "./screens/UpdateSessionPayment";

import Login from "./screens/login/Login";

//Api
import MyLaserApi from "./api/MyLaserApi";

//store
import store from "./redux/Store";
import styled from "styled-components";

import "react-time-picker-input/dist/components/TimeInput.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

AWS.config.update({
    accessKeyId: "AKIAICTGSHTMU6GNVM4A",
    secretAccessKey: "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",
    signatureVersion: "v4",
    region: "eu-central-1",
});

moment.locale("fr");

Sentry.init({
    dsn: "https://5e9a4ebd82bb437687c61ea8b71b0dfc@o245588.ingest.sentry.io/6681103",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (MyLaserApi.isLogged()) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/login" />;
                }
            }}
        />
    );
};

let PatientRouteHandler = function (props) {
    let params = useParams();
    let dispatch = useDispatch();

    useEffect(() => {
        MyLaserApi.getClient(params.id).then((res) => {
            if (res.status === 200) {
                let visitedClients = localStorage.getItem("visited_clients");

                if (visitedClients) {
                    visitedClients = JSON.parse(visitedClients);
                } else {
                    visitedClients = [];
                }

                const clientId = params.id;
                const existingClientIndex = visitedClients.findIndex(
                    (client) => client.id === clientId
                );

                if (existingClientIndex !== -1) {
                    visitedClients.splice(existingClientIndex, 1);
                }

                visitedClients.push({
                    id: params.id,
                    date: new Date(),
                    firstname: res.data.firstname,
                    lastname: res.data.lastname,
                });

                if (visitedClients.length > 10) {
                    visitedClients = visitedClients.slice(-10);
                }

                localStorage.setItem(
                    "visited_clients",
                    JSON.stringify(visitedClients)
                );

                dispatch({
                    type: "SET_CLIENT",
                    payload: res.data,
                });
            }
        });
    }, []);

    return <></>;
};

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;
    position: relative;
    height: auto;
`;
const Content = styled.div`
    width: 100%;
    min-height: 100vh;
    height: auto;
    transition: all 0.3s;
    background: transparent;
    position: relative;
`;

global.numberWithSpaces = (x, shouldDo) => {
    if (x === undefined) {
        return 0;
    }

    if (shouldDo === false) {
        return x;
    }

    var parts = x.toFixed(2).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
};

global.onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

function App() {
    useEffect(() => {
        registerLocale("fr", fr);
        setDefaultLocale("fr");

        setInterval(() => {
            if (MyLaserApi.isLogged()) {
                MyLaserApi.getMe().then((me) => {
                    if (me.status === false) {
                        localStorage.clear();
                        window.location.reload();
                    }

                    if (me.role == 1) {
                        MyLaserApi.getAgencies();
                    }
                });
            }
        }, 1000 * 60);

        if (MyLaserApi.isLogged()) {
            MyLaserApi.getMe().then((me) => {
                if (me.status === false) {
                    localStorage.clear();
                    window.location.reload();
                }

                if (me.role == 1) {
                    MyLaserApi.getAgencies();
                }
            });
        }
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <Wrapper>
                    <SideBar />

                    <Content>
                        <ToastContainer newestOnTop={true} />

                        <PrivateRoute
                            path="/patients/:id/*"
                            component={PatientRouteHandler}
                        ></PrivateRoute>

                        <Switch>
                            <Route
                                exact
                                path="/login"
                                render={() => {
                                    if (!MyLaserApi.isLogged()) {
                                        return <Login />;
                                    } else {
                                        return (
                                            <Redirect to="/redirect"></Redirect>
                                        );
                                    }
                                }}
                            ></Route>

                            <PrivateRoute
                                path="/admin"
                                component={AdminEmployees}
                                exact
                            />
                            <PrivateRoute
                                path="/admin/employees"
                                component={AdminEmployees}
                                exact
                            />
                            <PrivateRoute
                                path="/admin/sessions"
                                component={AdminSessions}
                                exact
                            />
                            {/* <PrivateRoute path='/admin/oussama' component={AdminOuss} exact /> */}

                            <PrivateRoute
                                path="/admin/patients"
                                component={Patients}
                                exact
                            />
                            <PrivateRoute
                                exact
                                path="/admin/agencies"
                                component={AdminAgencies}
                            />

                            <PrivateRoute
                                exact
                                path="/admin/agencies/:id"
                                component={AdminAgency}
                            />
                            <PrivateRoute
                                exact
                                path="/admin/invoices/:id"
                                component={AdminInvoices}
                            />
                            <PrivateRoute
                                exact
                                path="/admin/notenprotocoles"
                                component={NotesAndProtocoles}
                            />

                            <PrivateRoute
                                path="/admin/employees/:id/activity"
                                component={MyActivity}
                                exact
                            />

                            <PrivateRoute path="/home" component={Home} exact />
                            <PrivateRoute
                                path="/patients"
                                component={Patients}
                                exact
                            />
                            <PrivateRoute
                                path="/notenprotocoles"
                                component={NotesAndProtocoles}
                                exact
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/informations"
                                component={PatientInformations}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/laser/notes"
                                component={PatientTreatmentLaserNotesList}
                            />
                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/laser/notes/:id_session/details"
                                component={PatientTreatmentLaserNotesSingle}
                            />

                            {/* LASER */}
                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/:option(laser)"
                                component={ListSession}
                            />
                            {/* Soin Visage */}
                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/:option(mesotherapy|hydrafacial|prx|laser-frax|laser-frax-mes|carbon-peel|blemiderm|peri-oculaire-meso)"
                                component={ListSession}
                            />
                            {/* Soin Corps & Cheuveux */}
                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/:option(prp|hair-bleaching|cryolipolise|peeling-intimate)"
                                component={ListSession}
                            />
                            {/* Medecine Est. */}
                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/:option(ah|peeling|varicosite-couperose|laser-frax-mes|acne-active|taches-brune|detatouage|endolift|botox|dermamelan)"
                                component={ListSession}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/:option(unknow)"
                                component={ListSession}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/consents"
                                component={TreatmentConsent}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/consents/create"
                                component={ConsentCreate}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/quotations/add"
                                component={PatientConsentAdd}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/payments"
                                component={Payment}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/products"
                                component={PatientProducts}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/quotations/:type"
                                component={PatientQuotationFromType}
                            />
                            {window.country === "fr" ? (
                                <PrivateRoute
                                    exact
                                    path="/patients/:id/quotations/:type/add"
                                    component={PatientCreateQuotationFromType}
                                />
                            ) : (
                                <PrivateRoute
                                    exact
                                    path="/patients/:id/quotations/:type/add"
                                    component={PatientCreateQuotationFromTypeMA}
                                />
                            )}

                            <PrivateRoute
                                exact
                                path="/patients/:id/quotations/:type/add-validate"
                                component={
                                    PatientCreateQuotationValidateFromType
                                }
                            />

                            {/* <PrivateRoute path='/patients/:id/treatments/:id_session/update' component={UpdateSession} exact /> */}

                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/add-session/:type"
                                component={CreateSession}
                            />
                            {/* <PrivateRoute exact path='/patients/:id/treatments/add-session/:type/details' component={AddSessionDetails} /> */}

                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/update-session/:type/:id_session"
                                component={UpdateSession}
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/treatments/update-session/ah/:id_session/follows"
                                component={FollowAHInjection}
                            />

                            <PrivateRoute
                                exact
                                path="/sessions/payments/update"
                                component={UpdateSessionPayment}
                            />

                            <PrivateRoute
                                exact
                                path="/mnp"
                                component={PatientTreatmentLaserNotesList}
                            />

                            <PrivateRoute
                                path="/account"
                                component={MyAccount}
                                exact
                            />
                            <PrivateRoute
                                path="/activity"
                                component={MyActivity}
                                exact
                            />

                            <PrivateRoute
                                exact
                                path="/patients/:id/quotations"
                                component={Quotation}
                            />
                            <PrivateRoute
                                exact
                                path="/patients/:id/payment/add-price"
                                component={AddSessionPaymentStepTwo}
                            />
                            <PrivateRoute
                                exact
                                path="/patients/:id/payment/add"
                                component={AddSessionPaymentStepOne}
                            />
                            <PrivateRoute
                                exact
                                path="/consent/:id"
                                component={ConsentShow}
                            />
                            <PrivateRoute
                                exact
                                path="/consent/:id/signature"
                                component={ConsentSign}
                            />

                            <Route
                                render={() => {
                                    let role = localStorage.getItem("role");

                                    if (role && role == 1) {
                                        return <Redirect to="/admin" />;
                                    }

                                    return <Redirect to="/home" />;
                                }}
                            ></Route>

                            <Route
                                render={() => {
                                    if (MyLaserApi.isLogged()) {
                                        return <Redirect to="/home"></Redirect>;
                                    } else {
                                        return <Redirect to="/login" />;
                                    }
                                }}
                            />
                        </Switch>
                    </Content>
                </Wrapper>
            </Router>
        </Provider>
    );
}

export default App;
