
import store from '../redux/Store';


console.log(window.baseUrl)

export default class MyLaserApi {

    static baseUrl = window.baseUrl

    static isLogged() {
        return MyLaserApi.getToken() != null;
    }

    static getToken() {
        return localStorage.getItem('token')
    }

    static setToken(token) {
        return localStorage.setItem('token', token)
    }

    static me() {
        return JSON.parse(localStorage.getItem('me'))
    }

    static setMe(me) {
        return localStorage.setItem('me', JSON.stringify(me))
    }

    static handleErrors(errors) {
        let errorMsgs = '';
        errors.map((e) => {
            errorMsgs = errorMsgs + '\n' + e.msg;
        });
        return errorMsgs;
    }

    static unauth() {

        return new Promise((resolve) => {
            localStorage.removeItem('token');
            localStorage.removeItem('me');
            // console.log('wa')
            resolve();
        });

    };

    static call({method = "GET", url, body}) {
        let furl = window.baseUrl + `${url}`

        let options = {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + MyLaserApi.getToken()
            }
        }

        if (body && method != "GET") {
            options.body = JSON.stringify(body)
        }

        return new Promise((resolve, reject) => {
            fetch(furl, options).then((response) => {
                response.json().then((res) => {
                    resolve(res)
                });
            });
        });

    };

    static getAgencies() {
        return new Promise((resolve) => {
            MyLaserApi.call({url: "/agencies"}).then(({data}) => {

                store.dispatch({
                    type: 'SET_AGENCIES',
                    payload: data
                })

                resolve(data)

            })
            
        })

    }



    // static getQuotationItems(gender, type) {

    //     return new Promise((resolve, reject) => {
    //             method: 'GET',
    //             headers: {
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //             }
    //         }).then((response) => {
    //             response.json().then((res) => {
    //                 // if (res.status === 200) {

    //                 // if (gender === 'female' && type === "face") {
    //                 //     store.dispatch({ type: 'SET_FEMALE_FACE_AREAS', payload: res.data })
    //                 // } else if (gender === 'female' && type === "body") {
    //                 //     store.dispatch({ type: 'SET_FEMALE_BODY_AREAS', payload: res.data })
    //                 // } else if (gender === 'male' && type === "face") {
    //                 //     store.dispatch({ type: 'SET_MALE_FACE_AREAS', payload: res.data })
    //                 // } else if (gender === 'male' && type === "body") {
    //                 //     store.dispatch({ type: 'SET_MALE_BODY_AREAS', payload: res.data })
    //                 // }

    //                 // }
    //                 resolve(res);

    //             });
    //         })
    //             .catch((error) => {
    //                 reject(error);
    //             });
    //     });
    // }

    static getQuotationPricing(gender, pack, client, voucher, force, oldids) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/api/quotations/pricing?gender=${gender}&pack=${pack}&light=true${voucher ? "&voucher=" + voucher: ''}${force ? "&force=" + force: ''}${oldids ? "&oldids=" + oldids: ''}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: (new URLSearchParams(client).toString())
            }).then((response) => {

                response.json().then((res) => {
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static auth({ email, password }) {

        console.log(window)
        console.log(window.baseUrl)

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + '/auth', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            }).then((response) => {
                // console.log("response: ", response)
                response.json().then((res) => {
                    // console.log("res: ", res)
                    resolve(res)
                    if (res.status === 200) {
                        MyLaserApi.setToken(res.data.token)

                    }
                    else {
                        reject(MyLaserApi.handleErrors(res.errors));
                    }
                });
            });

        });

    };

    static getMe() {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + '/users/me', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res.data);
                        store.dispatch({
                            type: 'SET_USER',
                            payload: res.data
                        })
                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static UpdateMe(user) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + '/users/me', {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(user)
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('update me: ', res)
                    resolve(res);
                    if (res.status === 200) {

                        MyLaserApi.getMe()
                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };


    static UpdateMyPicture(picture) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + '/users/me/picture', {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(picture)
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('update my picture: ', res)
                    resolve(res);
                    if (res.status === 200) {

                        MyLaserApi.getMe()
                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };


    static UpdatePassword(password) {
        // console.log(password)
        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + '/users/me/password', {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(password)
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('update password: ', res)
                    if (res.status === 200) {
                        resolve(res);
                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };


    static getAllClients(offset, limit, sort, needle, unsave) {
        let url = window.baseUrl + `/agencies/clients`
        //
        if (offset != undefined) {
            url = url + `?offset=${offset}&limit=${limit}&sort=${sort}`
        }
        if (needle) {
            url = url + `&needle=${needle}`
            
        }
        // if (column) {
        //     url = url + `&${column}`
        // }
        // console.log(url)
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                // console.log('response: ', response)
                response.json().then((res) => {
                    // console.log('clients: ', res)
                    if (res.status === 200) {
                        resolve(res);
                        if (!unsave) {
                            store.dispatch({
                                type: 'SET_CLIENTS',
                                payload: res.data
                            })
                        }

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static getClient(id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                // console.log('response: ', response)
                response.json().then((res) => {
                    // console.log('getClient: ', res)
                    if (res.status === 200) {
                        resolve(res);

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };


    static UpdateClient(client, id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(client)
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('update client: ', res)
                    resolve(res);
                    if (res.status === 200) {


                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static UpdateClientDetails(id, data) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/details`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(data)
            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                    if (res.status === 200) {


                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static CreateClient(client, id) {
        // console.log('client: ', client)
        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(client)
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('create client: ', res)
                    resolve(res);
                    if (res.status === 200) {


                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static createClientTreatmentPayment(id, treatment_id, payment) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/treatments/${treatment_id}/payments`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(payment)
            }).then((response) => {
                response.json().then((res) => {

                    if (res.status == 201) {
                        resolve(res);
                    } else {
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static createPayment(payment, id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/payments`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(payment)
            }).then((response) => {
                response.json().then((res) => {

                    if (res.status == 201) {
                        resolve(res);
                    } else {
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static createPaymentRefund(refund, id, id_refund) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/payments/${id_refund}/refunds`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(refund)
            }).then((response) => {
                response.json().then((res) => {

                    if (res.status == 201) {
                        resolve(res);
                    } else {
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static getClientPayment(id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/payments`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
            }).then((response) => {
                response.json().then((res) => {

                    if (res.status == 200) {
                        resolve(res);
                    } else {
                        reject(res.errors);
                    }
                    
                });
            });
        });

    };

    static CreateClientSession(session, id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/sessions`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(session)
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('create session: ', res)
                    resolve(res);
                    if (res.status === 200) {

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static GetAgenciesClientsIdSession(id, session_id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/${session_id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },

            }).then((response) => {
                // console.log('response: ', response)
                response.json().then((res) => {
                    // console.log('PostAgenciesClientsIdSessions: ', res)
                    resolve(res);
                    // if (res.status === 200) {
                    //     resolve(res);

                    // } else {
                    //     reject(res.errors);
                    // }
                });
            });

        });

    }

    static UpdateAgenciesClientsIdSessions(id, session, session_id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/${session_id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(session)

            }).then((response) => {
                // console.log('response: ', response)
                response.json().then((res) => {
                    // console.log('PostAgenciesClientsIdSessions: ', res)
                    resolve(res);
                    // if (res.status === 200) {
                    //     resolve(res);

                    // } else {
                        console.log(reject)
                    //     reject(res.errors);
                    // }
                });
            });

        });

    }

    static CreateAgenciesClientsIdSessionsFollows(id, session, session_id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/${session_id}/follows`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(session)

            }).then((response) => {
                // console.log('response: ', response)
                response.json().then((res) => {
                    // console.log('PostAgenciesClientsIdSessions: ', res)
                    resolve(res);
                    // if (res.status === 200) {
                    //     resolve(res);

                    // } else {
                        // console.log(reject)
                    //     reject(res.errors);
                    // }
                });
            });

        });

    }

    static PostAgenciesClientsIdSessions(id, session) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(session)

            }).then((response) => {
                // console.log('response: ', response)
                response.json().then((res) => {
                    // console.log('PostAgenciesClientsIdSessions: ', res)
                    resolve(res);
                    // if (res.status === 200) {
                    //     resolve(res);

                    // } else {
                        console.log(reject)
                    //     reject(res.errors);
                    // }
                });
            });

        });
    }

    static PostAgenciesClientsIdTreatments(id, treatment) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/treatments`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(treatment)

            }).then((response) => {
                // console.log('response: ', response)
                response.json().then((res) => {
                    // console.log('PostAgenciesClientsIdSessions: ', res)
                    resolve(res.data);
                    // if (res.status === 200) {
                    //     resolve(res);

                    // } else {
                        console.log(reject)
                    //     reject(res.errors);
                    // }
                });
            });

        });
    }

    static GetAgenciesClientsIdTreatments(id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/treatments`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('GetAgenciesClientsIdSessions: ', res)
                    if (res.status === 200) {
                        resolve(res);

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static GetAgenciesClientsIdSessionsFPayment(id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/fpayments`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('GetAgenciesClientsIdSessions: ', res)
                    if (res.status === 200) {
                        resolve(res);

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static GetAgenciesClientsIdSessions(id, type) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/sessions?type=eq:${type}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('GetAgenciesClientsIdSessions: ', res)
                    if (res.status === 200) {
                        resolve(res);

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static GetAgencyInvoices(id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/${id}/clients/payments`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('GetAgenciesClientsIdSessions: ', res)
                    if (res.status === 200) {
                        resolve(res);

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static GetPatientNotes(id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/notes`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static CreatePatientNote(id, note) {

        console.log("baba ?")

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/notes`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(note)

            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static DeletePatientNotes(id, note_id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/notes/${note_id}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static UpdatePatientNotes(id, note_id, note) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/notes/${note_id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(note)

            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static DeleteTreatmentDiscount(id, discount_id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/treatments/${id}/discounts/${discount_id}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static UnsuspectTreatmentDiscount(id, discount_id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/${id}/treatments/discounts/${discount_id}/suspects `, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static ResetSession(id, session_id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/${session_id}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static CreateNote(id, session_id, note) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/${session_id}/notes`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(note)

            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static UpdateSessionNote(id, session_id, note) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/${session_id}/notes`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(note)

            }).then((response) => {
                response.json().then((res) => {
                    resolve(res);
                });
            });

        });
    }

    static CreateNoteTrack(id, session_id, note) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/${session_id}/notes/trackings`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(note)

            }).then((response) => {
                response.json().then((res) => {
                    // console.log('CreateNote: ', res)
                    resolve(res);

                });
            });

        });
    }

    static GetSession(id, session_id) {

        return new Promise((resolve, reject) => {

            fetch(window.baseUrl + `/agencies/clients/${id}/sessions/${session_id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    // console.log('CreateNote: ', res)
                    resolve(res);

                });
            });

        });
    }

    static GetClientResults(id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/agencies/clients/${id}/results`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res);

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static GetConsents() {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/consents`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    // console.log("consents: ", res)
                    resolve(res);
                    if (res.status === 200) {


                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static GetUserResults(id) {

        return new Promise((resolve, reject) => {
            fetch(window.baseUrl + `/users/${id}/results`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        resolve(res);

                    } else {
                        // console.log(reject)
                        reject(res.errors);
                    }
                });
            });
        });

    };

    static getPatientQuotations(patient_id) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/${patient_id}/quotations`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {

                response.json().then((res) => {
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static convertQuotationToSessions(patient_id, quotation_id, obj = {}) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/${patient_id}/quotations/${quotation_id}/sessions`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(obj)
            }).then((response) => {

                response.json().then((res) => {
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });

    }

    

    static upgradeQuotation(patient_id, quotation_id) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/${patient_id}/quotations/${quotation_id}/upgrade`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {

                response.json().then((res) => {
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static deleteQuotation(patient_id, quotation_id, force) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/${patient_id}/quotations/${quotation_id}${force ? "?force=true" : ""}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {

                response.json().then((res) => {
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static deleteQuotationOther(patient_id, quotation_id, force) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/${patient_id}/quotations/others/${quotation_id}${force ? "?force=true" : ""}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {

                response.json().then((res) => {
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static signConsent(id, image) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/consents/${id}/sign`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
                ,
                body: JSON.stringify(image)
            }).then((response) => {

                response.json().then((res) => {
                    // console.log("signConsent: ", res)
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static saveConsentHTML(id, data) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/consents/${id}/html`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
                ,
                body: JSON.stringify(data)
            }).then((response) => {

                response.json().then((res) => {
                    // console.log("signConsent: ", res)
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static getConsent(id) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/consents/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {

                response.json().then((res) => {
                    // console.log("getConsent: ", res)
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static deleteConsent(id) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/consents/${id}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {

                response.json().then((res) => {
                    // console.log("getConsent: ", res)
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static getNotesAndProtocoles() {
     
        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/notesandprotocoles`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });

    }

    static getClientConsents(id) {
     
        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/${id}/consents`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }

            }).then((response) => {
           
                response.json().then((res) => {
                    // console.log("getClientConsents: ", res)
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });

    }

    static updatePayment(id, data) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/payments/${id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(data)
            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
            .catch((error) => {
                reject(error);
            });

        });

    }

    static deletePayments(ids) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/payments`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify({ids: ids})
            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
            .catch((error) => {
                reject(error);
            });
            
        });

    }

    static invoicePayments(ids) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/agencies/clients/payments/invoice`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify({ids: ids})
            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
            .catch((error) => {
                reject(error);
            });
            
        });

    }

    static treatmentAddDiscount(id, data) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/treatments/${id}/discounts`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(data)
            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
            .catch((error) => {
                reject(error);
            });
            
        });

    }

    static updateEmployee(id, data) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/users/${id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(data)
            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
            .catch((error) => {
                reject(error);
            });
            
        });

    }

    static createEmployee(data) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/users`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(data)
            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
            .catch((error) => {
                reject(error);
            });
            
        });

    }

    static sendPasswordToEmployee(id) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/employees/${id}/password`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                }
            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
            .catch((error) => {
                reject(error);
            });
            
        });

    }

    static createNotesAndProtocoles(data) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/notesandprotocoles`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + MyLaserApi.getToken()
                },
                body: JSON.stringify(data)
            }).then((response) => {
           
                response.json().then((res) => {
                    resolve(res);
                });
            })
            .catch((error) => {
                reject(error);
            });
            
        });

    }

}
