import styled from "styled-components";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Container, Col, Form } from "react-bootstrap";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Header from "../../components/Header";
//api

// import MyLaserApi from "../../../api/MyLaserApi";

import DataTable from "react-data-table-component";

import { useSelector } from "react-redux";

import Swal from "sweetalert2";

import Products from "../../resources/Products";

import { generateTreamentAndSessions } from "../../services/QGeneration";

import MyLaserApi from "../../api/MyLaserApi";

const HeaderPatient = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    width: 100%;
    background-color: #b4cfd7;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.blue {
        color: #102935;
    }
    &.gray {
        color: #9fa9ae;
    }
    &.underline {
        text-decoration-line: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
    &.custom-width {
        width: 50%;
    }
    &.center {
        align-items: center;
    }
`;

const ContainerTreatment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    margin-top: 20px;
    width: 96%;
`;

function PatientTreatmentLaser() {
    let params = useParams();

    const [products, setProducts] = useState(Products);

    const [needle, setNeedle] = useState("");

    const [seeHistory, setSeeHistory] = useState(false);

    const [sessions, setSessions] = useState([]);

    const [showOnlySelected, setShowOnlySelected] = useState(false);

    useEffect(() => {
        MyLaserApi.GetAgenciesClientsIdSessions(params.id, "products").then(
            (res) => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setSessions(
                            res.data
                                .sort(function (a, b) {
                                    return a.treatment_id - b.treatment_id;
                                })
                                .map((session) => {
                                    return {
                                        date: moment(session.created_at).format(
                                            "LL"
                                        ),
                                        forced_price: session.forced_price,
                                        data: JSON.parse(session.data),
                                    };
                                })
                        );
                    }
                }
            }
        );
    }, []);

    return (
        <Container fluid className="App">
            <Row>
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header type="client" />

                    <ContainerTreatment>
                        {sessions.length > 0 && (
                            <Row
                                className="spaced-items center"
                                style={{ marginBottom: "20px" }}
                            >
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={() =>
                                            setSeeHistory(!seeHistory)
                                        }
                                    />{" "}
                                    <Text>
                                        Voir l'historique d'achats (
                                        {sessions.length})
                                    </Text>
                                </label>
                            </Row>
                        )}
                        {seeHistory && (
                            <Row
                                className="spaced-items center"
                                style={{ marginBottom: "20px" }}
                            >
                                <DataTable
                                    // noTableHead={true}
                                    // noHeader={true}
                                    style={{ marginTop: "10px", top: "10px" }}
                                    noDataComponent={
                                        <div
                                            style={{
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                            }}
                                        >
                                            Il n'y a aucun element à afficher
                                        </div>
                                    }
                                    pagination={false}
                                    data={sessions}
                                    columns={[
                                        {
                                            name: "Date",
                                            selector: "date",
                                            sortable: true,
                                            cell: (row) => {
                                                return <Text>{row.date}</Text>;
                                            },
                                        },
                                        {
                                            name: "Produits",
                                            selector: "products",
                                            sortable: true,
                                            cell: (row) => {
                                                return (
                                                    <Text>
                                                        {row.data.map(
                                                            (product) => {
                                                                return (
                                                                    <Text>
                                                                        {
                                                                            product.quantity
                                                                        }
                                                                        {" x "}
                                                                        {
                                                                            product.name
                                                                        }
                                                                        {" à "}
                                                                        {
                                                                            product.price
                                                                        }{" "}
                                                                        {
                                                                            window.currencySymbol
                                                                        }
                                                                        <br />
                                                                    </Text>
                                                                );
                                                            }
                                                        )}
                                                    </Text>
                                                );
                                            },
                                        },
                                        {
                                            name: "Prix",
                                            selector: "price",
                                            sortable: true,
                                            cell: (row) => {
                                                return (
                                                    <Text>
                                                        {row.forced_price}{" "}
                                                        {window.currencySymbol}
                                                    </Text>
                                                );
                                            },
                                        },
                                    ]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: "Elements par page",
                                        rangeSeparatorText: "de",
                                        selectAllRowsItem: false,
                                        selectAllRowsItemText: "Suivant",
                                    }}
                                />
                            </Row>
                        )}
                        <Row
                            className="spaced-items center"
                            style={{ marginBottom: "20px" }}
                        >
                            <Text className="bold blue">
                                Shop - <Text className="bold">Achat</Text>
                            </Text>

                            <Row
                                className="custom-width center"
                                style={{
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div
                                    style={{
                                        textDecorationLine: "none",
                                        width: "40%",
                                        marginRight: "10px",
                                    }}
                                >
                                    <Button
                                        style={{ width: "100%" }}
                                        className="blue"
                                        onClick={() => {
                                            if (
                                                products.reduce(
                                                    (old, current) =>
                                                        old +
                                                        parseInt(
                                                            current.quantity
                                                        ),
                                                    0
                                                ) === 0
                                            ) {
                                                return;
                                            }

                                            Swal.fire({
                                                title: "Confirmation",
                                                html: `Voulez-vous vraiment acheter ${products.reduce(
                                                    (old, current) =>
                                                        old +
                                                        parseInt(
                                                            current.quantity
                                                        ),
                                                    0
                                                )} produits pour un total de ${products
                                                    .reduce(
                                                        (old, current) =>
                                                            old +
                                                            parseInt(
                                                                current.quantity
                                                            ) *
                                                                parseFloat(
                                                                    current.price
                                                                ),
                                                        0
                                                    )
                                                    .toFixed(2)} ${
                                                    window.currencySymbol
                                                } ?`,
                                                showCancelButton: true,
                                                confirmButtonText: "Oui",
                                                cancelButtonText: "Non",
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    let {
                                                        treatment,
                                                        sessions,
                                                    } =
                                                        generateTreamentAndSessions(
                                                            {
                                                                data: {
                                                                    price: products.reduce(
                                                                        (
                                                                            old,
                                                                            current
                                                                        ) =>
                                                                            old +
                                                                            parseInt(
                                                                                current.quantity
                                                                            ) *
                                                                                parseFloat(
                                                                                    current.price
                                                                                ),
                                                                        0
                                                                    ),
                                                                    session_count: 1,
                                                                    free: false,
                                                                },
                                                                type: "products",
                                                                type_human:
                                                                    "Achat de produits",
                                                                stype: "ecommerce",
                                                                subdata:
                                                                    products.filter(
                                                                        (
                                                                            product
                                                                        ) =>
                                                                            parseInt(
                                                                                product.quantity
                                                                            ) >
                                                                            0
                                                                    ),
                                                            }
                                                        );

                                                    treatment.sessions =
                                                        sessions;

                                                    // history.push(
                                                    //     `/patients/${params.id}/payment/add-price`,
                                                    //     {
                                                    //         checked: [treatment],
                                                    //     }
                                                    // );

                                                    MyLaserApi.call({
                                                        method: "POST",
                                                        url: `/agencies/clients/${params.id}/quotations/products`,
                                                        body: {
                                                            type: treatment.type,
                                                            type_human:
                                                                treatment.type_human,
                                                            stype: treatment.stype,
                                                            treatment:
                                                                treatment,
                                                        },
                                                    }).then((quotation) => {
                                                        // history.push(
                                                        //     `/patients/${params.id}/quotations/${params.type}`
                                                        // );
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        Acheter{" "}
                                        {products.reduce(
                                            (old, current) =>
                                                old +
                                                parseInt(current.quantity),
                                            0
                                        )}{" "}
                                        produits pour{" "}
                                        {products
                                            .reduce(
                                                (old, current) =>
                                                    old +
                                                    parseInt(current.quantity) *
                                                        parseFloat(
                                                            current.price
                                                        ),
                                                0
                                            )
                                            .toFixed(2)}{" "}
                                        {window.currencySymbol}
                                    </Button>
                                </div>
                            </Row>
                        </Row>

                        <Row
                            className="spaced-items center"
                            style={{ marginBottom: "20px" }}
                        >
                            <HeaderPatient>
                                <Row
                                    className="spaced-items"
                                    style={{ marginTop: 0 }}
                                >
                                    <Input
                                        style={{ width: "100%" }}
                                        noIcon
                                        className="small"
                                        placeholder="Ecrivez le nom du produit que vous cherchez ici..."
                                        value={needle}
                                        onChange={(e) =>
                                            setNeedle(e.target.value)
                                        }
                                        id="input-needle"
                                    />
                                </Row>
                            </HeaderPatient>
                        </Row>
                        <div
                            style={{
                                borderRadius: "10px",
                                width: "100%",
                                marginBottom: "25px",
                            }}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() =>
                                        setShowOnlySelected(!showOnlySelected)
                                    }
                                />{" "}
                                <Text>
                                    Afficher uniquement les produits
                                    selectionnés
                                </Text>
                            </label>
                            <br />
                            <br />
                            <DataTable
                                style={{ marginTop: "10px", top: "10px" }}
                                noDataComponent={
                                    <div
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        Il n'y a aucune séance à faire
                                    </div>
                                }
                                pagination={false}
                                data={products.filter((product) => {
                                    if (showOnlySelected) {
                                        return product.quantity > 0;
                                    }

                                    if (needle === "") {
                                        return product;
                                    }

                                    if (
                                        product.name

                                            .toLowerCase()
                                            .replace(/\s+/g, "") // remove all spaces
                                            .replace(/-/g, "")
                                            .includes(
                                                needle
                                                    .replace(/\s+/g, "") // remove all spaces
                                                    .replace(/-/g, "")
                                                    .toLowerCase()
                                            )
                                    ) {
                                        return product;
                                    }
                                })}
                                columns={[
                                    {
                                        name: "Produit",
                                        selector: "image",
                                        sortable: true,
                                        cell: (row) => {
                                            return (
                                                <img
                                                    src={row.image}
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        marginTop: "10px",
                                                        marginBottom: "10px",
                                                    }}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        name: "Nom",
                                        selector: "name",
                                        sortable: true,
                                        cell: (row) => {
                                            return <Text>{row.name}</Text>;
                                        },
                                    },

                                    {
                                        name: "Prix",
                                        selector: "price",
                                        sortable: true,
                                        cell: (row) => {
                                            return (
                                                <Text>
                                                    {row.price}{" "}
                                                    {window.currencySymbol}
                                                </Text>
                                            );
                                        },
                                    },

                                    {
                                        name: "Quantité",
                                        selector: "quantity",
                                        sortable: true,
                                        cell: (row) => {
                                            return (
                                                <select
                                                    style={{
                                                        backgroundColor:
                                                            "#F8F9FB",
                                                        // marginBottom: 20,
                                                        padding: 5,
                                                        paddingTop: 6,
                                                        paddingBottom: 6,
                                                        display: "flex",
                                                        borderRadius: 5,
                                                        // width: width,
                                                        fontSize: 14,
                                                    }}
                                                    onChange={(e) => {
                                                        setProducts(
                                                            products.map(
                                                                (product) => {
                                                                    if (
                                                                        product.id ===
                                                                        row.id
                                                                    ) {
                                                                        product.quantity =
                                                                            e.target.value;
                                                                    }
                                                                    return product;
                                                                }
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <option>0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                    <option>8</option>
                                                    <option>9</option>
                                                    <option>10</option>
                                                </select>
                                            );
                                        },
                                    },
                                ]}
                                onRowClicked={(row) => {
                                    // if (specialNoteMode === true) {
                                    //     if (row.el.special_note_status == 0) {
                                    //         setModalShow(true);
                                    //         setSession(row.el);
                                    //     } else {
                                    //         history.push(
                                    //             `/patients/${params.id}/treatments/laser/notes/${row.el.id}/details`
                                    //         );
                                    //     }
                                    // }
                                }}
                                paginationComponentOptions={{
                                    rowsPerPageText: "Elements par page",
                                    rangeSeparatorText: "de",
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: "Suivant",
                                }}
                            />
                        </div>
                    </ContainerTreatment>
                </Col>
            </Row>
        </Container>
    );
}

export default PatientTreatmentLaser;
