import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Container, Col, Form, Badge } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

import "react-datepicker/dist/react-datepicker.css";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import SessionHeader from "../../../components/SessionHeader";

import MyLaserApi from "../../../api/MyLaserApi";

import { toast } from "react-toastify";

import moment from "moment";
import Swal from "sweetalert2";

import QRCode from "react-qr-code";

import ModalC from "../../../components/Modal";

import { Provider, useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../components/ButtonLoader";

const CustomSpan = styled.div`
    margin-right: 10px;
    padding: 10px 30px 15px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 3px 1px rgba(64, 60, 67, 0.16);
    background-color: white;
    width: auto;
    display: inline-block;
    margin-top: 10px;

    &.disabled {
        background-color: #f0f0f0;
        color: #9fa9ae;
    }
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.label {
        font-size: 13px;
        margin-bottom: 10px;
    }
    &.title {
        font-family: CenturyGothicBold;
        color: #6b7389;
    }
    &.gray {
        color: #6b7389;
    }
`;

const Box = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
`;

function AddConsent(props) {
    const history = useHistory();

    return (
        <Container fluid>
            <Row>
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "25px",
                    }}
                >
                    <Button
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Fermer la page
                    </Button>
                </Col>
            </Row>

            <Consent />
        </Container>
    );
}

let consentsSoins = [
    {
        type_human: "Epilation Laser",
        type: "laser",
    },
    {
        type_human: "Traitement capillaire",
        type: "traitement-capillaire",
    },
    {
        type_human: "Peeling Intime",
        type: "peeling-intime",
    },
    {
        type_human: "Hollywood Peel",
        type: "carbon-peel",
    },
    {
        type_human: "Détatouage",
        type: "detatouage",
    },
    {
        type_human: "Laser Frax Réjuvénation",
        type: "traitement-cicatrice-et-remodelage",
    },
    {
        type_human: "Hair bleaching",
        type: "hair-bleaching",
    },
    {
        type_human: "Blemiderm",
        type: "blemiderm",
    },
    {
        type_human: "MESOPEEL® / MESOHYAL®",
        type: "mesopeel-mesohyal",
    },
];

let consentsMes = [
    {
        type_human: "Acide Hyaluronique",
        type: "acide-hyaluronique",
    },
    {
        type_human: "Toxine Botulique",
        type: "toxine-botulique",
    },
    {
        type_human: "Peeling TCA",
        type: "peeling-tca",
    },
    {
        type_human: "Varicosité / Couperose",
        type: "laser-vasculaire",
    },
    {
        type_human: "Acné active",
        type: "gold-tonning",
    },
    {
        type_human: "Vergetures",
        type: "traitement-cicatrice-et-remodelage",
    },
    {
        type_human: "Cicatrices / Cicatrices d’acné",
        type: "traitement-cicatrice-et-remodelage",
    },
    {
        type_human: "Tâches brunes / Melasma / Lentigo",
        type: "traitement-des-lesions-pigmentaires-melasma",
    },
    {
        type_human: "Détatouage",
        type: "detatouage",
    },
    {
        type_human: "Endolift",
        type: "endolift",
    },
    {
        type_human: "Dermamelan",
        type: "dermamelan",
    },
];

function Consent() {
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const client = useSelector((state) => state.client);

    let sigCanvas = useRef();
    let sigCanvasMed = useRef();

    const [accepted, setAccepted] = useState(false);
    const [accepted2, setAccepted2] = useState(false);
    const [accepted3, setAccepted3] = useState(false);

    const [signed, setSigned] = useState(false);
    const [signedMed, setSignedMed] = useState(false);
    const [comment, onCommentChange] = useState("");

    const [type, setType] = useState(null);

    const [more, setMore] = useState({});

    const [tmpConsent, setTmpConsent] = useState(null);

    const [showQrCode, setShowQrCode] = useState(false);

    let [consents, setConsets] = useState([]);

    const [isLoading, setLoading] = useState(false);

    const [rand, setRand] = useState(Math.floor(Math.random() * 10000000));

    useEffect(() => {
        MyLaserApi.getClientConsents(params.id).then((res) => {
            if (res.status === 200) {
                setConsets(res.data);
            }
        });

        if (location.search !== "") {
            let search = new URLSearchParams(location.search);

            let stype = search.get("type");

            if (stype) {
                let ccc = [...consentsSoins, ...consentsMes].find(
                    (el) => el.type === stype
                );

                if (ccc) {
                    generateConsentBefore({
                        type: ccc.type,
                        type_human: ccc.type_human,
                    });
                }
            }
        }
    }, []);

    const isMedicalType = (type) => {
        return [
            "acide-hyaluronique",
            "toxine-botulique",
            "peeling-tca",
            "laser-vasculaire",
            "gold-tonning",
            "traitement-des-lesions-pigmentaires-melasma",
            "detatouage",
            "endolift",
            "dermamelan",
        ].includes(type);
    };

    const generateConsentBefore = (type, more) => {
        setLoading(true);

        if (type.type !== "") {
            MyLaserApi.call({
                method: "POST",
                url: `/agencies/clients/${params.id}/consents`,
                body: {
                    type: type.type,
                    type_human: type.type_human,
                    ...more,
                },
            }).then((res) => {
                setTmpConsent(res.data);
                setType(type);
                setMore(more);
                setTimeout(() => setLoading(false), 1000);
            });
        }
    };

    const signConsent = (type) => {
        return MyLaserApi.call({
            method: "PUT",
            url: `/agencies/clients/${params.id}/consents/${tmpConsent.id}`,
            body: {
                type: type.type,
                type_human: type.type_human,
                image: sigCanvas.toDataURL(),
                imageMed: isMedicalType(type.type)
                    ? sigCanvasMed.toDataURL()
                    : null,
                doctor_note: comment,
                ...more,
            },
        }).then((res) => {
            setTimeout(() => {
                history.go(-1);
            }, 1000);
        });
    };

    const showNumber = (number) => {
        if (number > 0) {
            return (
                <Badge
                    pill
                    bg="success"
                    style={{
                        background: "#8cb197",
                    }}
                >
                    {number}
                </Badge>
            );
        } else {
            return <></>;
        }
    };

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="spinner"></div>
                <br />
            </div>
        );
    }

    if (type === null) {
        return (
            <div style={{ paddingLeft: 60 }}>
                <h5 style={{}}>EPILATION LASER & AUTRES SOINS</h5>

                <div>
                    {consentsSoins.map((item, index) => {
                        return (
                            <CustomSpan
                                className={` ${
                                    item.type === "" ? "disabled" : ""
                                } `}
                                onClick={() => generateConsentBefore(item)}
                            >
                                {item.type_human}{" "}
                                {showNumber(
                                    consents.filter(
                                        (c) =>
                                            c.type === item.type &&
                                            c.version == "2"
                                    ).length
                                )}
                            </CustomSpan>
                        );
                    })}
                </div>

                {
                    <>
                        <h5 style={{ marginTop: 20 }}>MÉDECINE ESTHÉTIQUE</h5>
                        <div>
                            {consentsMes.map((item, index) => {
                                return (
                                    <CustomSpan
                                        className={` ${
                                            item.type === "" ? "disabled" : ""
                                        } `}
                                        onClick={() =>
                                            generateConsentBefore(item)
                                        }
                                    >
                                        {item.type_human}{" "}
                                        {showNumber(
                                            consents.filter(
                                                (c) =>
                                                    c.type === item.type &&
                                                    c.version == "2"
                                            ).length
                                        )}
                                    </CustomSpan>
                                );
                            })}
                        </div>
                    </>
                }
                <h5 style={{ marginTop: 20 }}>AUTRES</h5>
                <div>
                    {[
                        {
                            type_human: "Décharge de responsabilité",
                            type: "decharge-responsabilite",
                        },
                        {
                            type_human: "Autorisation parentale",
                            type: "autorisation-parentale",
                        },
                    ].map((item, index) => {
                        return (
                            <CustomSpan
                                onClick={() => {
                                    if (
                                        item.type === "decharge-responsabilite"
                                    ) {
                                        Swal.fire({
                                            title: "Pour quel traitement ?",
                                            text: "Pour quel traitement souhaitez-vous signer la décharge de responsabilité ?",
                                            input: "text",
                                            showCancelButton: true,
                                            inputPlaceholder:
                                                "Nom du traitement",
                                        }).then((result) => {
                                            if (result.value) {
                                                generateConsentBefore(item, {
                                                    nom_du_traitement:
                                                        result.value,
                                                });
                                            }
                                        });
                                    } else if (
                                        item.type === "autorisation-parentale"
                                    ) {
                                        Swal.fire({
                                            title: "Par qui et pour quel traitement ?",
                                            showCancelButton: true,
                                            html:
                                                '<input id="swal-input1-a" class="swal2-input" style="width: 80%" placeholder="Nom du parent / tuteur">' +
                                                '<input id="swal-input2-a" class="swal2-input" style="width: 80%" placeholder="Nom du traitement" >',
                                            preConfirm: () => {
                                                return [
                                                    document.getElementById(
                                                        "swal-input1-a"
                                                    ).value,
                                                    document.getElementById(
                                                        "swal-input2-a"
                                                    ).value,
                                                ];
                                            },
                                        }).then((result) => {
                                            if (result.value) {
                                                generateConsentBefore(item, {
                                                    nom_du_parent:
                                                        result.value[0],
                                                    nom_du_traitement:
                                                        result.value[1],
                                                });
                                            }
                                        });
                                    }
                                }}
                            >
                                {item.type_human}{" "}
                                {showNumber(
                                    consents.filter(
                                        (c) =>
                                            c.type === item.type &&
                                            c.version == "2"
                                    ).length
                                )}
                            </CustomSpan>
                        );
                    })}
                </div>
            </div>
        );
    }

    if (type !== null) {
        return (
            <>
                <Row>
                    <Col md={6} className="col">
                        <div style={{ width: "90%" }}>
                            <h2>Aperçu du consentement</h2>

                            <Button
                                className="blue"
                                onClick={() => {
                                    setShowQrCode(true);
                                }}
                            >
                                Afficher le QRCode
                            </Button>

                            <Button
                                className="reverse"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    setRand(
                                        Math.floor(Math.random() * 10000000)
                                    );
                                }}
                            >
                                Recharger le consentement
                            </Button>
                            <br />
                            <br />

                            <iframe
                                src={
                                    "https://docs.google.com/viewer?url=" +
                                    tmpConsent.link +
                                    "&embedded=true&rand=" +
                                    rand
                                }
                                style={{ width: "100%", height: "90vh" }}
                            ></iframe>
                        </div>
                    </Col>

                    <Col md={6} className="col">
                        <h2
                            onClick={() => {
                                setShowQrCode(true);
                            }}
                        >
                            Section à remplir
                        </h2>

                        <br />
                        <br />

                        {isMedicalType(type.type) && (
                            <>
                                Recommandations du medecin:
                                <Input
                                    noIcon
                                    className="multiline"
                                    placeholder="Les recommandations du medecin"
                                    value={comment}
                                    textArea
                                    onChange={(e) =>
                                        onCommentChange(e.target.value)
                                    }
                                />
                            </>
                        )}

                        <Form.Check
                            id={`read-n-accepted`}
                            type="checkbox"
                            label="J'ai lu et j'accepte "
                            onChange={() => setAccepted(!accepted)}
                            style={{ marginBottom: 15, marginTop: 15 }}
                        />

                        <Form.Check
                            id={`read-n-accepted2`}
                            type="checkbox"
                            label="J'autorise My Laser à utiliser mes données personnelles conformément à la réglementation RGPD pour me proposer des offres marketing (réductions, concours, nouveaux traitements, ...)"
                            onChange={() => setAccepted2(!accepted2)}
                        />

                        <Form.Check
                            id={`read-n-accepted3`}
                            type="checkbox"
                            label="Je renonce à mon délai de réflexion et désire entamer mon traitement sans délai."
                            onChange={() => setAccepted3(!accepted3)}
                        />

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Text
                                className="title"
                                style={{ marginTop: 15, marginBottom: 5 }}
                            >
                                Signature du patient
                                <br />
                            </Text>

                            <Box style={{ width: "100%", height: "350px" }}>
                                <div style={{ border: "1px solid black" }}>
                                    <SignatureCanvas
                                        ref={(ref) => {
                                            sigCanvas = ref;
                                        }}
                                        penColor="black"
                                        canvasProps={{
                                            width: "400px",
                                            height: "300px",
                                            className: "sigCanvas",
                                        }}
                                        onEnd={(props) => {
                                            setSigned(true);
                                        }}
                                        backgroundColor="rgb(255,255,255)"
                                    />
                                </div>

                                <span
                                    onClick={() =>
                                        sigCanvas.clear() && setSigned(false)
                                    }
                                >
                                    Effacer & Recommencer la signature
                                </span>
                            </Box>

                            {isMedicalType(type.type) && (
                                <>
                                    <Text
                                        className="title"
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 5,
                                        }}
                                    >
                                        Signature du medecin
                                        <br />
                                    </Text>

                                    <Box
                                        style={{
                                            width: "100%",
                                            height: "350px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <SignatureCanvas
                                                ref={(ref) => {
                                                    sigCanvasMed = ref;
                                                }}
                                                penColor="black"
                                                canvasProps={{
                                                    width: "400px",
                                                    height: "300px",
                                                    className: "sigCanvas",
                                                }}
                                                onEnd={(props) => {
                                                    setSignedMed(true);
                                                }}
                                                backgroundColor="rgb(255,255,255)"
                                            />
                                        </div>

                                        <span
                                            onClick={() =>
                                                sigCanvasMed.clear() &&
                                                setSignedMed(false)
                                            }
                                        >
                                            Effacer & Recommencer la signature
                                        </span>
                                    </Box>
                                </>
                            )}

                            <ButtonLoader
                                disabled={(() => {
                                    if (!signed || !accepted) {
                                        return true;
                                    } else {
                                        if (
                                            isMedicalType(type.type) &&
                                            !signedMed
                                        ) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                })()}
                                style={{ marginTop: 40, marginBottom: 40 }}
                                onClick={() => signConsent(type)}
                            >
                                Valider mon consentement
                            </ButtonLoader>
                        </div>
                    </Col>
                </Row>

                <ModalC
                    show={showQrCode}
                    onHide={() => setShowQrCode(false)}
                    title={"QRCode consentement"}
                    onButtonClick={() => setShowQrCode(false)}
                    size="lg"
                >
                    <QRCode
                        size={256}
                        style={{
                            height: "auto",
                            maxWidth: "80%",
                            width: "80%",
                            alignSelf: "center",
                        }}
                        value={
                            "https://docs.google.com/viewer?url=" +
                            tmpConsent.link +
                            "&embedded=true"
                        }
                        viewBox={`0 0 256 256`}
                    />
                </ModalC>
            </>
        );
    }
}

function PdfViewer({ pdfUrl }) {
    const iframeRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Add an event listener to the iframe's onLoad event
        const handleLoad = () => {
            setIsLoading(false);
        };

        iframeRef.current.addEventListener("load", handleLoad);

        // Clean up the event listener when the component unmounts
        return () => {
            iframeRef.current.removeEventListener("load", handleLoad);
        };
    }, []);

    useEffect(() => {
        if (!isLoading) {
            // The iframe has loaded successfully, no need to reload
            return;
        }

        // If the iframe is still loading after 1 second, reload it
        const reloadTimer = setTimeout(() => {
            iframeRef.current.src = iframeRef.current.src;
        }, 1000);

        // Clean up the timer when the component unmounts or when the iframe loads
        return () => {
            clearTimeout(reloadTimer);
        };
    }, [isLoading]);

    return (
        <iframe
            ref={iframeRef}
            src={pdfUrl}
            style={{ width: "100%", height: "90vh" }}
        ></iframe>
    );
}

export default AddConsent;
