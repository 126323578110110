
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Loader from "react-js-loader";
import { Container, Col, Form } from "react-bootstrap";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Header from "../../../components/Header";
import SideBar from "../../../components/SideBar";
import CreateSpecialNoteModal from "../../../components/CreateSpecialNoteModal";
import Table from "../../../components/Table";
import * as ServiceDataTableHelper from "../../../services/ServiceDataTableHelper";
//api

import MyLaserApi from "../../../api/MyLaserApi";

import ModalCreateTreatment from "./ModalCreateTreatment"

import DataTable from 'react-data-table-component';

import { useSelector } from "react-redux";

import { Carousel } from "react-responsive-carousel"
import Swal from "sweetalert2";

function isEmptyObject(obj) {
    for(var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
}

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.blue {
        color: #102935;
    }
    &.gray {
        color: #9fa9ae;
    }
    &.underline {
        text-decoration-line: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
    &.custom-width {
        width: 50%;
    }
    &.center {
        align-items: center;
    }
`;

const OptionButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    background-color: #edf0f5;
    color: #102935;
    padding: 10px;
    font-family: CenturyGothicRegular;
    border: none;
    border-right: 1px solid #cdd3d9;
    border-radius: 10px;

    &.selected {
        font-family: CenturyGothicBold;
        background-color: #102935;
        color: #fff;
        border-radius: 10px;
    }
    &.last {
        border-right: none;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    &.t33 {
        width: 33.33%;
    }

`;
const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
`;

const ContainerTreatment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    margin-top: 20px;
    width: 96%;
`;

function PatientTreatmentLaser() {
    
    const [modalShow, setModalShow] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    
    const [modalAttachmentsIsVisible, setModalAttachmentsIsVisible] = useState(false);
    const [modalAttachments, setModalAttachments] = useState([]);

    const [specialNoteMode, setSpecialNoteMode] = useState(false)

    // const [option, setOption] = useState("laser");
    const [sessions, setSessions] = useState([]);
    const [session, setSession] = useState(null);
    const [loader, setLoading] = useState(false);
    const [note, setNote] = useState("");
    const [currentSession, setCurrentSession] = useState(null);

    const [consents, setConsents] = useState([])

    const [treatmentModal, showTreatmentModal] = useState(false)

    const [dragging, setDragging] = useState(null);

    const [payments, setPayments] = useState([]);

    const [ client, setClient ] = useState(null)

    const user = useSelector((state) => state.user)  
    
    let params = useParams();
    let history = useHistory();

    let reloadClient = () => {
        MyLaserApi.getClient(params.id).then((res) => {
            setClient(res.data)
        })
    } 

    let reloadSessions = () => {

        setSessions([]);
        reloadClient()
        

        MyLaserApi.getClientPayment(params.id).then((res) => {
            setPayments(res.data)


            MyLaserApi.getClientConsents(params.id).then((res) => {
                if (res.status === 200) {
                    setConsents(res.data);
                }
            })

            MyLaserApi.GetAgenciesClientsIdSessions(
                params.id,
                params.option
            ).then((res) => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setDataForm(res.data.sort(function(a, b) {
                            return a.treatment_id - b.treatment_id;
                        }));
                    }
                }
            });

        })
  
    }

    useEffect(() => {
        reloadSessions()
    }, [params.option]);

    let setDataForm = (data) => {

        let lines = data.filter((sd) => {

            if (sd.date.includes('1970')) {
                return data.find((sdd) => sdd.date.includes('1970') && sdd.type == sd.type && sdd.area == sd.area).id == sd.id
            }

            return true

        }).map((el) => {

            let rest = 0
            let waiting = 0

            if (el.date.includes('1970')) {

                let ss = data.filter((sdd) => sdd.date.includes('1970') && sdd.type == el.type && sdd.area == el.area)

                rest = ss.length

                ss.map((s) => {

                    if (payments.find((p) => {
                        return (p.treatment_id == s.treatment_id)
                    })) {
                        waiting += 1
                    }

                })

            }

            let single = JSON.parse(el.data);

            return {
                single: single,
                id: el.id,
                el: el,
                rest: rest,
                waiting: waiting
            };

        });

        setSessions(lines);
    };

    let generateColumns = (type) => {

        let clns = [
            ServiceDataTableHelper.columns.date({history, params, user, type, reload: reloadSessions}),
        ];

        if (type === "laser") {

            clns = [
                ServiceDataTableHelper.columns.date({history, params, user, payments, type, reload: reloadSessions, dragging, setDragging, onDrag: (dragged, target) => { 
                    
                    setDragging(null)

                    Swal.fire({
                        title: 'Attention',
                        text: "Vous êtes sur de dupliquer les paramètres de cette séance ?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Oui',
                        cancelButtonText: 'Non',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.isConfirmed) {

                            let obj = {
                                type: target.type,
                                type_human: target.type_human,
                                area: target.area || "-",
                                data: JSON.parse(dragged.data),
                                employee_id: dragged.employee_id,
                            }

                            console.log(target.date)

                            if (target.date.includes('1970')) {
                                obj.date = new Date()
                            } else {
                                obj.date = target.date
                            }

                            MyLaserApi.UpdateAgenciesClientsIdSessions(
                                params.id,
                                obj,
                                target.id
                            ).then(() => {
                                // history.goBack()
                                reloadSessions()
                            });

                        }
                    })

                }}),
                {
                    name: "Zone",
                    selector: (row) => {

                        let rtn = [ row.el.area ]

                        if (row.single.extra && !isEmptyObject(row.single.extra)) {
                            rtn = [ ...rtn, <br/>, row.el.area + " (Lèvres)" ]
                        }

                        return rtn
                    },
                    wrap: true,
                    grow: 2
                },
              
                ServiceDataTableHelper.columns.laser_with_label({name: "Taille spot", column: "spot"}),
    
                ServiceDataTableHelper.columns.laser_with_label({name: "Fluence", column: "fluence"}),
    
                ServiceDataTableHelper.columns.laser_with_label({name: "Pulse", column: "pulse"}),
                
                ServiceDataTableHelper.columns.laser_normal({name: "Alex/Yag", column: "technologie"}),
                
            ]

        } else if (type === "mesotherapy") {

        } else if (type === "prx") {

            clns = [
                ...clns,
                {
                    name: "Type",
                    selector: (row) =>  row.el.area,
                    wrap: true,
                    grow: 2
                },
            ]

        } else if (type === "hydrafacial") {

            clns = [
                ...clns,
                {
                    name: "Type",
                    selector: (row) =>  row.el.type,
                    wrap: true,
                    grow: 2
                },
            ]

        } else if (type.option === "ah" || type.option === "botox" || type.option === "peeling") {

            clns = [
                ...clns,
                {
                    name: "Zone",
                    selector: (row) =>  row.el.area,
                    wrap: true,
                    grow: 2
                },
                {
                    name: "Nb ml injecté",
                    selector: (row) => {
                        if (row.single.injection) {
                            let rtn = [ row.single.injection ]
    
                            if (params.option === "ah") {
                
                                row.el.follows.map((s) => {
                                    let sj = JSON.parse(s)
                                    rtn.push(<br/>)
                                    rtn.push(< span style={{color: "rgb(136 194 211)", fontSize: "11px"}} >+ {sj.data.injection} le {moment(sj.date).format("DD/MM/YYYY")}</span>)
                                })
    
                                rtn.push(<br/>)
                                rtn.push(<span
                                    style={{
                                        fontFamily: "CenturyGothicBold",
                                        fontSize: "12px",
                                        color: "rgb(136 194 211)"
                                    }}
                                    onClick={() => {
                                        history.push(`/patients/${params.id}/treatments/update-session/${params.option}/${row.el.id}/follows`)
                                    }}
                                >
                                    Ajouter un Suivi Injection
                                </span>)
    
                                rtn.push(<br/>)
                                rtn.push(<br/>)
                
                            }
    
                            return rtn
                            
                        } else {
                            return "N/A"
                        }
                    },
                    wrap: true,
                    grow: 2
                },
            ]

        } else if (type === "cryolipolyse") {

            clns = [
                ...clns,
                {
                    name: "Zone",
                    selector: (row) =>  row.el.area,
                    wrap: true,
                    grow: 2
                },
                {
                    name: <>Distance / Hauteur <br />/ Largeur</>,
                    selector: (row) => `${row.single.distance || "N/C"} / ${row.single.height || "N/C"} / ${row.single.width || "N/C"}`,

                    wrap: true,
                    grow: 2
                },
                {
                    name: "Applicateur",
                    selector: (row) => row.single.applicateur || "N/C"
                },
                {
                    name: "Refroidissement",
                    selector: (row) => row.single.refroidissement || "N/C"
                },
                {
                    name: "Aspiration",
                    selector: (row) => row.single.aspiration || "N/C"
                },
                {
                    name: "Durée",
                    selector: (row) => row.single.duree || "N/C"
                }

            ]

        } else if (type === "peeling-intimate") {

            clns = [
                ...clns,
                {
                    name: "Type",
                    selector: (row) =>  row.el.type_human,
                    wrap: true,
                    grow: 2
                },
                {
                    name: "Zone",
                    selector: (row) =>  row.el.area,
                    wrap: true,
                    grow: 2
                }

            ]

        } else if (type === "unknow") {
            clns = [
                ...clns,
                {
                    name: "Type",
                    selector: (row) =>  row.el.type_human,
                    wrap: true,
                    grow: 2
                },
            ]   
        }

        

        if (type != "laser") {
            clns.push({
                name: "Zone",
                selector: (row) =>  row.el.area,
                wrap: true,
                grow: 2
            })
        }

        return [
            ...clns,
            
            ServiceDataTableHelper.columns.employee({history, params}),

            ServiceDataTableHelper.columns.notes({onClick: (note) => {
                setShowNoteModal(true);
                setNote(note);
            }}),

            ServiceDataTableHelper.columns.attachments({onClick: (attachments) => {
                setModalAttachmentsIsVisible(true)
                setModalAttachments(attachments)
            }}),
        ]

    }

    return (
        <Container fluid className="App">

            {loader && (
                <LoaderContainer>
                    <Loader
                        type="spinner-circle"
                        bgColor={"#F0B594"}
                        size={70}
                    />
                </LoaderContainer>
            )}

            <Row>
                
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header type="client" />

                    <ContainerTreatment>

                        <Row className="spaced-items center" style={{marginBottom: "20px"}}>

                            <Text className="bold blue">
                                Informations Traitement - {" "}

                                <Text className="bold">
                                    { (params.option == "laser") && "Épilation laser" }
                                    { (params.option == "mesotherapy" || params.option == "hydrafacial" || params.option === "laser-frax" || params.option === "carbon-peel" || params.option === "prx") && "Soins visage" }
                                    { (params.option === "prp" || params.option === "hair-bleaching" || params.option === "cryolipolise" || params.option === "peeling-intimate") && "Soins corps & Cheveux" }
                                    { (["ah", "peeling", "varicosite-couperose", "laser-frax-mes", "acne-active", "taches-brune", "detatouage", "endolift"].includes(params.option)) && "Médecine esthétique" }
                                    {/* { (params.option === "cryolipolyse" || params.option === "hair" ) && "Autres traitements" } */}
                                    { (params.option === "unknow") && "Traitements non catégorisés" }
                                    { (params.option === "blemiderm") && "Blemiderm" }
                                    { (params.option === "botox") && "Autre Injection" }
                                    { (params.option === "dermamelan") && "Dermamelan" }
                                    { (params.option === "peri-oculaire-meso") && "Peri-Oculaire + Mésotherapie" }
                                </Text>

                            </Text>

                            <Row
                                className="custom-width center"
                                style={{
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                            >

                                <div style={{
                                    textDecorationLine: "none",
                                    width: "40%",
                                    marginRight: "10px",
                                    
                                }}>

                                    <Button style={{ width: "100%" }} className="blue" onClick={() => setSpecialNoteMode(!specialNoteMode)}>
                                        {specialNoteMode ? "Désactivé" : "Activé"} Note particulière
                                    </Button>

                                </div>


                            </Row>
                            
                        </Row>

                        {(params.option === "mesotherapy" || params.option === "hydrafacial" || params.option === "prx" || params.option === "laser-frax" || params.option === "carbon-peel") && <Row className="center" style={{marginBottom: "20px"}}S>
                            <OptionButton
                                className={params.option === "mesotherapy" ? "selected" : null}
                                onClick={() => history.push(`/patients/${params.id}/treatments/mesotherapy`)}
                            >
                                Mésothérapie
                            </OptionButton>
                            <OptionButton
                                className={params.option === "hydrafacial" ? "selected" : null}
                                onClick={() => history.push(`/patients/${params.id}/treatments/hydrafacial`)}
                            >
                                Hydrafacial
                            </OptionButton>

                            <OptionButton
                                className={params.option === "carbon-peel" ? "selected" : null}
                                onClick={() => history.push(`/patients/${params.id}/treatments/carbon-peel`)}
                            >
                                Hollywood Peel
                            </OptionButton>

                            
                            <OptionButton
                                className={params.option === "prx" ? "selected" : null}
                                onClick={() => history.push(`/patients/${params.id}/treatments/prx`)}
                            >
                                PRX
                            </OptionButton>
                            <OptionButton
                                className={params.option === "laser-frax" ? "selected" : null}
                                onClick={() => history.push(`/patients/${params.id}/treatments/laser-frax`)}
                            >
                                Laser Frax Réjuv.
                            </OptionButton>
                        </Row>}

                        {(params.option === "prp" || params.option === "hair-bleaching" || params.option === "cryolipolise" || params.option === "peeling-intimate") && <Row className="center" style={{marginBottom: "20px"}}>
                            <OptionButton
                                className={`t33 ${params.option === "prp" ? "selected" : null}`}
                                onClick={() => history.push(`/patients/${params.id}/treatments/prp`)}
                            >
                                PRP
                            </OptionButton>
                            <OptionButton
                                className={`t33 ${params.option === "hair-bleaching" ? "selected" : null}`}
                                onClick={() => history.push(`/patients/${params.id}/treatments/hair-bleaching`)}
                            >
                                Hair Bleaching
                            </OptionButton>
                           
                            <OptionButton
                                className={`t33 ${params.option === "cryolipolise" ? "selected" : null}`}
                                onClick={() => history.push(`/patients/${params.id}/treatments/cryolipolise`)}
                            >
                                Cryolipolise
                            </OptionButton>

                            <OptionButton
                                className={`t33 ${params.option === "peeling-intimate" ? "selected" : null}`}
                                onClick={() => history.push(`/patients/${params.id}/treatments/peeling-intimate`)}
                            >
                                Peeling Intime
                            </OptionButton>

                        </Row>}

                        {/* {(params.option === "cryolipolyse" || params.option === "hair" || params.option === "peeling-intimate") && <Row className="center" style={{marginBottom: "20px"}}>
                            
                            <OptionButton
                                className={`t33 ${params.option === "cryolipolyse" ? "selected" : null}`}
                                onClick={() => history.push(`/patients/${params.id}/treatments/cryolipolyse`)}
                            >
                                Cryolipolyse
                            </OptionButton>

                            <OptionButton
                                className={`t33 ${params.option === "peeling-intimate" ? "selected" : null}`}
                                onClick={() => history.push(`/patients/${params.id}/treatments/peeling-intimate`) }
                            >
                                Peeling Intime
                            </OptionButton>

                            <OptionButton
                                className={`t33 ${params.option === "hair" ? "selected" : null}`}
                                onClick={() => history.push(`/patients/${params.id}/treatments/hair`) }
                            >
                                Traitement capillaire
                            </OptionButton>

                        </Row>} */}

                        <div style={{borderRadius: "10px", width: "100%"}}>

                                {
                                    (() => {

                                        if (!client) {
                                            return
                                        }

                                        if (params.option === "laser") {
                                            let consent = consents.find((el) => el.type === "laser")

                                            if (!consent && !client.has_paper_laser_consent) {
                                                return <>
                                                    <span>Un consentement est necessaire pour commencer les séance de laser.</span> <br/>
                                                    <span> 

                                                    <Form.Check 
                                                        value={client.has_paper_laser_consent}
                                                        id={`has_paper_laser_consent`} 
                                                        type="checkbox" 
                                                        label="Le patient a déjà un consentement papier"
                                                        onChange={() => {

                                                            MyLaserApi.call({
                                                                method: "PUT",
                                                                url: `/agencies/clients/${params.id}/has_paper_laser_consent`,
                                                                data: {}
                                                            }).then(() => {
                                                                reloadClient()
                                                            })
                                                        }}

                                                    />

                                                    </span>
                                                    <Button className="blue custom-width" style={{textAlign: 'center', width: "30%"}} onClick={() => {
                                                        history.push(`/patients/${params.id}/consents/create?type=laser`)
                                                    }}>Generer un consentement</Button>
                                                </>
                                            }

                                        }

                                        return <DataTable
                                            noTableHead={true}
                                            noHeader={true}
                                            style={{marginTop: "10px", top: "10px"}}
                                            noDataComponent={<div style={{paddingTop: 10, paddingBottom: 10}}>Il n'y a aucune séance à faire</div>}
                                            pagination={false}
            
                                            data={sessions.filter((session) => session.el.date.includes('1970')).filter((session) => {
            
                                                if (specialNoteMode) {
                                                    return !session.el.date.includes('1970')
                                                } else {
                                                    return true
                                                }
                                                
                                            })}
            
                                            columns={generateColumns(params.option)}
            
                                            onRowClicked={(row) => {
            
                                                if (specialNoteMode === true) {
            
                                                    if (row.el.special_note_status == 0) {
                                                        setModalShow(true);
                                                        setSession(row.el);
                                                    } else {
                                                        history.push(`/patients/${params.id}/treatments/laser/notes/${row.el.id}/details`)
                                                    }
            
                                                }
            
                                            }}
            
                                            paginationComponentOptions={{
                                                rowsPerPageText: 'Elements par page',
                                                rangeSeparatorText: 'de',
                                                selectAllRowsItem: false,
                                                selectAllRowsItemText: 'Suivant',
                                            }}
            
                                        />



                                    })()
                                }
                                

                            

                            <hr />

                            {(() => {

                                let ssessions = sessions.filter((session) => !session.el.date.includes('1970')).filter((session) => {

                                    if (specialNoteMode) {
                                        return !session.el.date.includes('1970')
                                    } else {
                                        return true
                                    }
                                    
                                }).sort((s1, s2) => new Date(s2.el.date) - new Date(s1.el.date))

                                const groupedByDate = {};

                                ssessions.forEach((obj) => {
                                    const date = new Date(obj.el.date).toLocaleDateString();

                                    if (!groupedByDate[date]) {
                                        groupedByDate[date] = [];
                                    }

                                    groupedByDate[date].push(obj);
                                });

                                return Object.values(groupedByDate).map((group, index) => {

                                    return [<DataTable
                                        style={{marginBottom: "20px"}}
                                        noDataComponent={<div style={{paddingTop: 10, paddingBottom: 10}}>Il n'y a aucune séance à afficher</div>}
                                        pagination={false}
        
                                        noTableHead={index !== 0}
                                        noHeader={index !== 0}

                                        data={group}
        
                                        columns={generateColumns(params.option)}
                                        
                                        onRowClicked={(row) => {
        
                                            if (specialNoteMode === true) {
        
                                                if (row.el.special_note_status == 0) {
                                                    setModalShow(true);
                                                    setSession(row.el);
                                                } else {
                                                    history.push(`/patients/${params.id}/treatments/laser/notes/${row.el.id}/details`)
                                                }
        
                                            }
        
                                        }}
        
                                        conditionalRowStyles={[{
        
                                            when: (row) => row.el.special_note_status == 1,
                                                style: {
                                                    backgroundColor: 'rgba(215, 180, 180, 0.8)',
                                                },
                                            },{
                                            when: (row) => row.el.special_note_status == 2,
                                                style: {
                                                    backgroundColor: '#B4D7C1',
                                                },
                                            },
        
                                        ]}
        
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Elements par page',
                                            rangeSeparatorText: 'de',
                                            selectAllRowsItem: false,
                                            selectAllRowsItemText: 'Suivant',
                                        }}
        
                                    />, <hr style={{ width: "40%", marginLeft: "auto", marginRight: "auto" }} align="center" />]

                                })

                            })()}

                        </div>
                        
                    </ContainerTreatment>
                </Col>
            </Row>

            <CreateSpecialNoteModal
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                    reloadSessions()
                }}
                clientId={params.id}
                session={session}
            />

            <Modal
                show={showNoteModal}
                onHide={() => setShowNoteModal(false)}
                title={"Notes"}
            >
                <Text>{note}</Text>
            </Modal>

            <Modal
                show={modalAttachmentsIsVisible}
                onHide={() => setModalAttachmentsIsVisible(false)}
                title={"Pièces Jointes"}
                size="lg"
            >

                {/* 
                <Carousel emulateTouch={true} autoPlay={false} style={{width: "100%", height: "50vh"}} >
                    {
                        modalAttachments.map((file) => {
                            // return 
                                return <div > <img src={file} /> </div>
                        })
                    }
                </Carousel> 
                */}

                {
                    modalAttachments.map((file) => {
                        return <div style={{textAlign: 'center'}}>
                            <a href={file} target="_blank"> Ouvrir en grand </a> <br />
                            <img src={file} style={{maxWidth: "100%"}}/>
                        </div>
                    })
                }

            </Modal>

            <ModalCreateTreatment show={treatmentModal} setShow={showTreatmentModal} callback={() => reloadSessions()} />

        </Container>
    );
}

export default PatientTreatmentLaser;
